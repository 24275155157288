import * as React from 'react';
import { Icon } from '../Icon/Icon';

interface MarketModalRowProps {
  marketName: string;
  handleTableRowDelete: Function;
  selectedModalMarkets: { MarketName: string, TargetNumberOfCompletes: string, IncidenceRate: string }[];
  setCountryIsSelected: Function
}

export const MarketModalRow: React.FunctionComponent<MarketModalRowProps> = ({ marketName, handleTableRowDelete, selectedModalMarkets, setCountryIsSelected }) => {
  return (
    <>
      <tr>
        <td colSpan={1} className="ps-0 py-2 align-middle border-0">
          <div className="d-flex align-items-center">
            <Icon type="globe" className="d-flex align-content-center me-2" />
            <p className="text-primary mb-0">{marketName}</p>
          </div>
        </td>
        <td colSpan={1} className="pe-0 align-middle text-center border-0 delete">
          <div className="d-flex w-100">
            <button className='btn btn-transparent p-0' onClick={() => handleTableRowDelete(marketName)} >
              <Icon type="delete" />
            </button>
          </div>
        </td>
      </tr >
      {
        selectedModalMarkets.map(market => {
          if (market.MarketName === marketName) {
            setCountryIsSelected(true)
            return (
              <div className='text-danger' >
                <small>Country has already been added as Target market.</small>
              </div>
            )
          }
          return null
        })
      }
    </>
  )
};
