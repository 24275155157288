import * as React from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Spinner } from "react-bootstrap";

import { BiddingContextValues } from "../../../contexts/BiddingContext";
import { NavigationContextValues } from "../../../contexts/NavigationContext";
import { ModalMessage as Modal } from "../../shared/Modal/Modal";
import { ModalInterface } from "../../../interfaces/modalInterface";
import { fetchPost, fetchPostJson } from "../../../services/services";

const renderButton = ({ onClick, remainingTime }) => {
  return (remainingTime === 0 ?
    <button
      className=" text-primary bg-white text-decoration-underline border-0 p-0 fs-7 mb-4"
      onClick={onClick}
    >
      Resend verification code.
    </button> :
    React.Fragment
  )
}

const renderTime = (remainingTime) => {
  return (remainingTime > 0 ? <p className="text-primary bg-white fs-7 mb-4" >Please wait {remainingTime} seconds to resend verification code.</p> : React.Fragment)
}

interface VerifyUserProps {
}

export const VerifyUser: React.FunctionComponent<VerifyUserProps> = (props) => {
  const { setCurrentNavPage, setCurrentNavSubPage } = React.useContext(NavigationContextValues)
  const { bidLoading, setBidLoading, userEmail, setUserAuth } = React.useContext(BiddingContextValues)
  const [didMount, setDidMount] = React.useState<boolean>(false);
  const [OTP, setOTP] = React.useState<string>("");
  const [modal, setModal] = React.useState<ModalInterface>({ visibility: false, title: "", message: "" })

  React.useEffect(() => {
    if (!didMount) {
      setDidMount(false)
      if (OTP.length === 6) {
        setBidLoading(true)
        fetchPostJson(`/b/verifyemailcode?email=${userEmail}`, { VerificationCode: `${OTP}` })
          .then((res: { verified: boolean; }) => {
            if (res.verified) {
              setUserAuth(false);
              setCurrentNavPage("ProjectDetails");
              setCurrentNavSubPage("SurveyInformation");
            }
          })
          .catch((error: any) => { console.log(error); })
          .finally(() => {
            setBidLoading(false)
          })
      }
    }
  }, [OTP, didMount, setBidLoading, setCurrentNavPage, setCurrentNavSubPage, setUserAuth, userEmail]);

  const handleVerification = () => {
    if (OTP.length === 6) {
      setBidLoading(true)
      fetchPostJson(`/b/verifyemailcode?email=${userEmail}`, { VerificationCode: `${OTP}` })
        .then((res: { verified: boolean; }) => {
          if (res.verified) {
            setUserAuth(false);
            setCurrentNavPage("ProjectDetails");
            setCurrentNavSubPage("SurveyInformation");
          } else {
            setModal({ visibility: true, title: 'Error', message: ' Wrong Verification code. Please try again.' })
          }
        })
        .catch((error: any) => { console.log(error); })
        .finally(() => {
          setBidLoading(false)
        })
    } else {
      setModal({ visibility: true, title: 'Error', message: 'Please enter your Verication code' })
    }
  }
  const resendVerificationCode = () => {
    fetchPost(`/b/verify?email=${userEmail}`)
      .then((res: any) => {
        if (res.error) {
          setModal({ visibility: true, title: 'Error', message: 'Verification code not been send to your email address. Please try again.' })
        } else {
          setModal({ visibility: true, title: 'Success', message: 'A verification code has been resend to your email address. Please check and fill the information to proceed.' })
        }

      })
      .catch((err: any) => console.log(err))
  }

  return (
    <>
      {modal.visibility &&
        <Modal
          handleModal={setModal}
          visibility={modal.visibility}
          message={modal.message}
          title={modal.title}
        />
      }

      <div className="container-fluid ">
        <div className="row ps-4">
          <div className="w-100 max-width-500 py-5 mt-2 pe-2">
            <p className="display-7 text-primary fw-bold mb-3">
              Verify your email address
            </p>
            <p className="text-primary fs-6 mb-4">A verification code has been sent to {userEmail}</p>
            <p className="text-muted fs-6 mb-4">
              Please check your inbox and enter the verification code below to verify your email address. The code will expire in 12 hours.
            </p>
            <div>
              <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} className="otp-wrapper" />
              <ResendOTP maxTime={60} renderButton={renderButton} renderTime={renderTime} onResendClick={resendVerificationCode} bidLoading={bidLoading} />
            </div>
            <button type="button" onClick={handleVerification} className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
              {bidLoading ? <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                <span>Verifying...</span>
              </>
                : "Verify email address"}
            </button>
          </div>
        </div>
      </div>
    </>)
};