import * as React from 'react';
import "../../../assets/scss/navbar.scss"
import { useLocation } from 'react-router-dom';

import { Icon } from '../Icon/Icon';
import { ModalMessage as Modal } from '../Modal/Modal';

import { BiddingContextValues } from '../../../contexts/BiddingContext';
import { FeasibilityContextValues } from '../../../contexts/FeasibilityContext';
import { NavigationContextValues } from '../../../contexts/NavigationContext';
import { initialFullServiceValues } from '../../../state/initialSurveyTypeStates/intitialSurveyTypeStates';
import { ModalInterface } from '../../../interfaces/modalInterface';

interface NavbarProps {
}

const logo: string = require("../../../assets/img/walr-logo-bids.svg").default;
const stepperItemClasses = "list-group-item rounded-circle border-0  stepper-item p-0";
const stepperDotClasses = "list-group-item rounded-circle border-0 stepper-dot-item p-0";
const stepperPageNameClasses = "list-group-item bg-navbar border-0 p-0";

export const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
  const { currentNavPage, currentNavSubPage, setCurrentNavPage, setCurrentNavSubPage } = React.useContext(NavigationContextValues)
  const [modal, setModal] = React.useState<ModalInterface>({ visibility: false, title: "", message: "" })
  const {
    surveyInfoValues,
    setSurveyTypePrevState,
    adHocValues,
    trackerValues,
    fullService,
    setFullService,
    projectType,
    projectTypePrevState,
    setProjectTypePrevState,
    userEmail,
    userAuth,
    finalizeData
  } = React.useContext(BiddingContextValues);
  const { feasibilityGetValues } = React.useContext(FeasibilityContextValues);
  const location = useLocation()

  const RenderFeasibilityProps = ({ children }) => {
    if (location.pathname.includes("feasibility")) {
      return (
        <div className="row">
          <div className="col-12">
            <div className='row d-flex justify-content-between m-0 mb-3'>
              <p className='text-white mb-0 text-decoration-none small'>Reference number</p>
              <p className='text-white bold fs-4 text-decoration-none '>{feasibilityGetValues.referenceNumber}</p>
            </div>
            <div className='row d-flex justify-content-between m-0 mb-3'>
              <p className='text-white mb-0 text-decoration-none small'>Project number</p>
              <p className='text-white bold fs-4 text-decoration-none '>Not provided</p>
            </div>
          </div>
        </div>
      )
    } else {
      return children
    }
  }

  const handleNavigation = (navSubPage: string) => {
    if (finalizeData.bidReferenceNumber !== undefined) {
      setModal({ visibility: true, title: 'Success', message: 'Your bidding has finished. A member of the Walr sales team will be in contact with you shortly to confirm the costs and feasibility. We’ve also sent an email confirmation to your inbox.' })
    } else {
      if (currentNavSubPage === "VerifyUser") {
        if (userAuth) {
          setModal({ visibility: true, title: "Success", message: 'A verification code has been sent to your email address. Please check and fill the information to proceed.' })
        }
      }
      else if (navSubPage === "SurveyInformation") {
        if (userEmail !== "") {
          setCurrentNavPage("ProjectDetails");
          setCurrentNavSubPage("SurveyInformation");
        } else {
          return setModal({ visibility: true, title: "Error", message: 'Please provide your email to continue with the bid.' })
        }
      }
      else if (navSubPage === "SurveyDetails") {
        if (Object.values(surveyInfoValues).includes("") || projectType === "") {
          return setModal({ visibility: true, title: "Error", message: 'Please fill out the "Survey information" page before heading on to "Survey details"' })
        } else {
          if (
            (projectTypePrevState === "FullService" && projectType === "SampleOnly")
            && window.confirm('WARNING: This will change your project type from "Full Service" to "Sample Only" and the details provided for "Full Service" will be reset.')) {
            setFullService(initialFullServiceValues)
            setSurveyTypePrevState(surveyInfoValues.surveyType)
            setProjectTypePrevState(projectType)
            setCurrentNavSubPage(surveyInfoValues.surveyType)
          }
          else {
            setProjectTypePrevState(projectType)
            if (surveyInfoValues.surveyType === "AdHoc") {
              setCurrentNavPage("ProjectDetails")
              setCurrentNavSubPage("AdHoc");
            } else {
              setCurrentNavPage("ProjectDetails")
              setCurrentNavSubPage("Tracker")
            }
          }
        }
      }
      else if (navSubPage === "QuotasTargeting") {
        if (Object.values(surveyInfoValues).includes("") || projectType === "") {
          return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Survey information" tab to proceed with the bid.' })
        } else {
          if (surveyInfoValues.surveyType === "AdHoc") {
            if (projectType === "FullService" && (Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]) || Object.values(fullService).includes(""))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out your AdHoc survey details before heading on to "Quotas & targeting"' })
            }
            else if (projectType === "SampleOnly" && (Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out your AdHoc survey details before heading on to "Quotas & targeting"' })
            }
          }
          else if (surveyInfoValues.surveyType === "Tracker") {
            if (projectType === "FullService" && (Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]) || Object.values(fullService).includes(""))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out your Tracker survey details before heading on to "Quotas & targeting"' })
            }
            else if (projectType === "SampleOnly" && (Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out your Tracker survey details before heading on to "Quotas & targeting"' })
            }
          }
        }
        setCurrentNavPage("ProjectDetails")
        setCurrentNavSubPage("QuotasTargeting")
      }
      else if (navSubPage === "Summary") {
        if (userEmail === "") {
          return setModal({ visibility: true, title: "Error", message: 'Please provide your email to continue with the bid.' })
        } else {
          if (Object.values(surveyInfoValues).includes("") || projectType === "") {
            return setModal({ visibility: true, title: "Error", message: 'Please fill out the necessary information in "Project Details" tab to proceed with the bid.' })
          } else {
            if (surveyInfoValues.surveyType === "AdHoc") {
              if (projectType === "FullService" && (Object.values(surveyInfoValues).includes("") || Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]) || Object.values(fullService).includes(""))) {
                return setModal({ visibility: true, title: "Error", message: 'First two steps of "Project Details" are required to be filled with information to proceed with the bid.' })
              }
              else if (projectType === "SampleOnly" && (Object.values(surveyInfoValues).includes("") || Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]))) {
                return setModal({ visibility: true, title: "Error", message: 'First two steps of "Project Details" are required to be filled with information to proceed with the bid.' })
              }
            }
            else if (surveyInfoValues.surveyType === "Tracker") {
              if (projectType === "FullService" && (Object.values(surveyInfoValues).includes("") || Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]) || Object.values(fullService).includes(""))) {
                return setModal({ visibility: true, title: "Error", message: 'First two steps of "Project Details" are required to be filled with information to proceed with the bid.' })
              }
              else if (projectType === "SampleOnly" && (Object.values(surveyInfoValues).includes("") || Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]))) {
                return setModal({ visibility: true, title: "Error", message: 'First two steps of "Project Details" are required to be filled with information to proceed with the bid.' })
              }
            }
          }
        }
        setCurrentNavPage("Review")
        setCurrentNavSubPage("Summary")
      }
      else if (navSubPage === "BidProposal") {
        if (Object.values(surveyInfoValues).includes("") || projectType === "") {
          return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Project Details" tab to proceed with the bid.' })
        } else {
          if (surveyInfoValues.surveyType === "AdHoc") {
            if (projectType === "FullService" && (Object.values(surveyInfoValues).includes("") || Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]) || Object.values(fullService).includes(""))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Survey details" to proceed with the bid.' })
            }
            else if (projectType === "SampleOnly" && (Object.values(surveyInfoValues).includes("") || Object.values(adHocValues).includes("") || Object.values(adHocValues).includes([]))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Survey details" to proceed with the bid.' })
            }
          }
          else if (surveyInfoValues.surveyType === "Tracker") {
            if (projectType === "FullService" && (Object.values(surveyInfoValues).includes("") || Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]) || Object.values(fullService).includes(""))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Survey details" to proceed with the bid.' })
            }
            else if (projectType === "SampleOnly" && (Object.values(surveyInfoValues).includes("") || Object.values(trackerValues).includes("") || Object.values(trackerValues).includes([]))) {
              return setModal({ visibility: true, title: "Error", message: 'Please fill out the required information in "Survey details" to proceed with the bid.' })
            }
          }
        }
      }
    }
  }

  return <>
    {modal.visibility &&
      <Modal
        handleModal={setModal}
        visibility={modal.visibility}
        title={modal.title}
        message={modal.message}
      />
    }
    <div className='Navbar d-flex flex-column justify-content-between min-vh-100 p-5'>
      <div className="row">
        <div className="col-12 mb-5 p-0">
          <img src={logo} alt="" />
        </div>
        <RenderFeasibilityProps>
          <div className="col-12 d-flex mt-4 p-0">
            <ul className="list-group me-3 rounded-5 py-1 px-1 stepper">
              <li className={`${stepperItemClasses} mb-3 ${currentNavPage === "GetStarted" ? "active" : null}`}>1</li>
              <li className={`${stepperItemClasses} mb-3 ${currentNavPage === "ProjectDetails" ? "active" : null}`}
                onClick={() => handleNavigation("SurveyInformation")}>
                2
              </li>
              <li className={` ${stepperDotClasses} mb-3 ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("SurveyInformation")}>
                <div className={`stepper-dot rounded-circle ${currentNavSubPage === "SurveyInformation" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}></div>
              </li>
              <li className={`${stepperDotClasses} mb-3 ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("SurveyDetails")}>
                <div className={`stepper-dot rounded-circle ${currentNavSubPage === "AdHoc" || currentNavSubPage === "Tracker" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}></div>
              </li>
              <li className={`${stepperDotClasses} mb-3 ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("QuotasTargeting")}>
                <div className={`stepper-dot rounded-circle ${currentNavSubPage === "QuotasTargeting" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}></div>
              </li>
              <li className={`${stepperItemClasses} mb-3 ${currentNavPage === "Review" ? "active" : null}`}
                onClick={() => handleNavigation("Summary")}>
                3
              </li>
              <li className={`${stepperDotClasses} mb-3 ${currentNavPage === "Review" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("Summary")}>
                <div className={`stepper-dot rounded-circle ${currentNavSubPage === "Summary" ? "active" : null} ${currentNavPage === "Review" ? "nav-open" : "nav-close"}`}></div>
              </li>
              {/* <li className={`${stepperDotClasses} mb-3 ${currentNavPage === "Review" ? "nav-open" : "nav-close"}`}>
                <div className={`stepper-dot rounded-circle ${currentNavSubPage === "BidProposal" ? "active" : null} ${currentNavPage === "Review" ? "nav-open" : "nav-close"}`}></div>
              </li> */}
              <li className={`${stepperItemClasses} ${currentNavPage === "BidReference" ? "active" : null}`}>4</li>
            </ul>
            <ul className="list-group py-1 px-1">
              <li className={`${stepperPageNameClasses} mb-3 bold text-white ${currentNavPage === "GetStarted" ? "active" : null}`}>Get started</li>
              <li
                className={`${stepperPageNameClasses} mb-3 bold text-white`}
                onClick={() => handleNavigation("SurveyInformation")}>
                Project details
              </li>
              <li
                className={`${stepperPageNameClasses} mb-3 ps-3 text-light ${currentNavSubPage === "SurveyInformation" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("SurveyInformation")}>
                Survey information
              </li>
              <li
                className={`${stepperPageNameClasses} mb-3 ps-3 text-light ${currentNavSubPage === "AdHoc" || currentNavSubPage === "Tracker" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("SurveyDetails")}>
                Survey details
              </li>
              <li
                className={`${stepperPageNameClasses} mb-3 ps-3 text-light ${currentNavSubPage === "QuotasTargeting" ? "active" : null} ${currentNavPage === "ProjectDetails" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("QuotasTargeting")}>
                Choose your targeting
              </li>
              <li
                className={`${stepperPageNameClasses} mb-3 bold text-white`}
                onClick={() => handleNavigation("Summary")}>
                Review and finish
              </li>
              <li
                className={`${stepperPageNameClasses} mb-3 ps-3 text-light ${currentNavSubPage === "Summary" ? "active" : null} ${currentNavPage === "Review" ? "nav-open" : "nav-close"}`}
                onClick={() => handleNavigation("Summary")}>
                Summary
              </li>
              <li className={`${stepperPageNameClasses} bold text-white ${currentNavPage === "BidReference" ? "active" : null}`}>
                Confirmation
              </li>
            </ul>
          </div>
        </RenderFeasibilityProps>
      </div >
      <div className="row">
        <div className="col-12">
          <p className='d-flex justify-content-between m-0 mb-3 external'>
            <a href='https://walr.com/contact/' className='text-white text-decoration-none bold '>Help Center</a>
            <a href='https://walr.com/contact/' >
              <Icon type="help-external" fill="#fff" />
            </a>
          </p>
          <p className='d-flex justify-content-between m-0 external'>
            <a href='https://walr.com/' className='text-white text-decoration-none bold '>Go to Walr.com</a>
            <a href='https://walr.com/'>
              <Icon type="help-external" fill="#fff" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </>;
};