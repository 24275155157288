import * as React from 'react';

import { Icon } from '../../shared/Icon/Icon';
import { BiddingContextValues } from '../../../contexts/BiddingContext';

interface BidSubmittedProps {
}

export const BidReference: React.FunctionComponent<BidSubmittedProps> = (props) => {
  const { finalizeData, userEmail } = React.useContext(BiddingContextValues);

  return <div className="container-fluid">
    <div className="row ps-default">
      <div className="col-12 d-flex justify-content-end align-items-center pt-4">
        <p className="p-0 m-0 me-2 text-primary">{userEmail}</p>
        <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
        <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>

      <div className="w-100 max-width-500 py-5 mt-2 ps-3 pe-0">
        <p className='mb-4'></p>
        <p className="display-7 text-bold fw-bold mb-3 text-primary">Your bid request has been sent</p>
        <p className="text-muted mb-3">
          A member of the Walr sales team will be in contact with you shortly to confirm the costs and feasibility.
        </p>
        <p className="text-muted mb-4">
          We’ve also sent an email confirmation to your inbox.
        </p>
        <div className="rounded-3 bg-ultra-light border border-1 border-light p-3 mb-4">
          <p className="text-muted mb-1">Your quote reference number is:</p>
          <p className="text-primary fw-semibold fs-4 mb-0">{finalizeData.bidReferenceNumber}</p>
        </div>
        <div className="d-grid d-flex">
          <a href="https://platform.walr.com/" target="_blank" className="btn border border-1 border-primary d-flex w-auto text-primary justify-content-center align-items-center" rel="noreferrer"><span>Go to Walr Platform</span><Icon type="help-external" className="ms-4 fill-primary" /></a>
        </div>
      </div>
    </div>
  </div>;
};
