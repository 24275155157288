import * as React from 'react';
import { Icon } from '../Icon/Icon';

interface MarketRowProps {
  marketName: string;
  targetNumberOfCompletes: string;
  incidenceRate: string;
  marketInputsAccess: boolean;
  handleTableRowUpdate: Function;
  handleTableRowDelete: Function;
}

export const MarketRow: React.FunctionComponent<MarketRowProps> = ({ marketName, targetNumberOfCompletes, incidenceRate, marketInputsAccess, handleTableRowDelete, handleTableRowUpdate }) => {
  return <tr>
    <td colSpan={2} className="ps-2 align-middle">
      <div className="d-flex align-items-center">
        <Icon type="globe" className="d-flex align-content-center me-1" />
        <p className="text-primary mb-0">{marketName}</p>
      </div>
    </td>
    <td colSpan={1}>
      <div className="border rounded p-0 my-2 me-2">
        <input
          type="number"
          min={1}
          max={15000}
          value={targetNumberOfCompletes}
          className={`form-control text-primary m-0 ${marketInputsAccess ? "border" : "border-0"}`}
          required
          disabled={marketInputsAccess}
          onChange={(e) => handleTableRowUpdate(e, marketName, "targetNumberOfCompletes")}
        />
      </div>
    </td>
    <td colSpan={1}>
      <div className="border rounded p-0 my-2 me-2">
        <div className="input-group d-flex justify-content-center">
          <input
            type="number"
            min={1}
            max={100}
            value={incidenceRate}
            className={`form-control text-primary m-0 ${marketInputsAccess ? "border" : "border-0"}`}
            required
            disabled={marketInputsAccess}
            onChange={(e) => handleTableRowUpdate(e, marketName, "incidenceRate")}
          />
          <span className="input-group-text bg-white text-primary border-0 p-1 pe-2"><small>%</small></span>
        </div>
      </div>
    </td>
    <td colSpan={1} className="align-middle text-center delete pe-1">
      <button onClick={() => handleTableRowDelete(marketName)} className="btn btn-transparent border-0 p-1 delete">
        <Icon type="delete" />
      </button>
    </td>
  </tr>;
};
