import * as React from 'react';

import { SurveyInformation } from './SurveyInformation/SurveyInformation';
import { AdHoc } from './AdHoc/AdHoc';
import { Tracker } from './Tracker/Tracker';
import { QuotasTargeting } from './QuotasTargeting/QuotasTargeting';
import { NavigationContextValues } from '../../contexts/NavigationContext';

interface ProjectDetailsProps {
}

export const ProjectDetails: React.FunctionComponent<ProjectDetailsProps> = (props) => {
  const { currentNavSubPage } = React.useContext(NavigationContextValues)
  return <>
    {currentNavSubPage === "SurveyInformation" ? <SurveyInformation /> :
      currentNavSubPage === "AdHoc" ? <AdHoc /> :
        currentNavSubPage === "Tracker" ? <Tracker /> :
          currentNavSubPage === "QuotasTargeting" ? <QuotasTargeting /> : null
    }
  </>;
};