import React, { createContext, useState } from 'react';

import {
  adHocValuesInterface,
  BiddingContextInterface,
  errorsInterface,
  fullServiceInterface,
  quotasTargetValuesInterface,
  surveyInfoValuesInterface,
  trackerValuesInterface,
  finalizeDataInterface,
  CustomAudienceModal
} from '../interfaces/biddingInterfaces'
import { getCurrentDate } from '../utils/getCurrentDate';

const BiddingContext = createContext({} as BiddingContextInterface);

export const Provider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  const [bidLoading, setBidLoading] = React.useState<boolean>(false);
  const [userAuth, setUserAuth] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [projectType, setProjectType] = useState<string>("");
  const [surveyTypePrevState, setSurveyTypePrevState] = React.useState<string>("");
  const [projectTypePrevState, setProjectTypePrevState] = React.useState<string>("");

  const [surveyInfoValues, setSurveyInfoValues] = useState<surveyInfoValuesInterface>({
    surveyName: "",
    surveyType: "",
    containsPersonalData: "",
    selectedMarkets: [],
    completeAsap: false,
    interviewLength: "",
  })

  const [adHocValues, setAdHocValues] = useState<adHocValuesInterface>({
    adHocLaunchDate: getCurrentDate(),
    adHocTargetEndDate: "",
  })

  const [trackerValues, setTrackerValues] = useState<trackerValuesInterface>({
    trackerLaunchDate: getCurrentDate(),
    trackerTargetEndDate: "",
    completeAsap: false,
    trackerFrequency: "",
    trackerExclusionStatus: [],
    trackerExclusionDuration: "",
  })

  const [quotasTargetValues, setQuotasTargetValues] = useState<quotasTargetValuesInterface>({
    quotasNatRep: false,
    quotasGenPop: false,
    quotasCustomAudience: false,
  });

  const [fullService, setFullService] = useState<fullServiceInterface>({
    programmingComplexity: "",
    dataProcess: false,
    translation: false,
    numberOfQuestions: "",
  })

  const [finalizeData, setFinalizeData] = React.useState<finalizeDataInterface>({
    discount: null,
    id: "",
    markets: [{
      costPerInterview: "",
      feasibility: 0,
      incidenceRate: 0,
      marketName: "",
      targetNumberOfCompletes: 0,
      totalCost: "",
    }],
    totalEstimatedCost: "",
    bidReferenceNumber: undefined,
  });

  const [customAudienceModalValues, setCustomAudienceModalValues] = useState<CustomAudienceModal[]>([{
    MarketName: "",
    TargetNumberOfCompletes: 0,
    IncidenceRate: 0,
    screening: [],
    quotas: []
  }])

  const [errors, setErrors] = useState<errorsInterface>({
    userEmail: false,
    surveyName: false,
    surveyType: false,
    projectType: false,
    containsPersonalData: false,
    adHocLaunchDate: false,
    adHocTargetEndDate: false,
    trackerLaunchDate: false,
    trackerTargetDate: false,
    trackerFrequency: false,
    trackerExclusionStatus: false,
    trackerExclusionDuration: false,
    numberOfQuestions: false,
    programmingComplexity: false,
    interviewLength: false,
    dataProcess: false,
    translation: false,
    completeAsap: false,
    selectedMarkets: false,
  })

  const bidValues = {
    userAuth,
    setUserAuth,
    userEmail,
    setUserEmail,
    projectType,
    setProjectType,
    surveyTypePrevState,
    setSurveyTypePrevState,
    projectTypePrevState,
    setProjectTypePrevState,
    fullService,
    setFullService,
    surveyInfoValues,
    setSurveyInfoValues,
    adHocValues,
    setAdHocValues,
    trackerValues,
    setTrackerValues,
    quotasTargetValues,
    setQuotasTargetValues,
    finalizeData,
    setFinalizeData,
    bidLoading,
    setBidLoading,
    customAudienceModalValues,
    setCustomAudienceModalValues,
    errors,
    setErrors,
  }

  return (
    <BiddingContext.Provider value={bidValues}>
      {props.children}
    </BiddingContext.Provider>
  );
};

export const BiddingContextProvider = Provider;
export const BiddingContextValues = BiddingContext;