import React from 'react'

interface AccordionProps {
  id: string;
  target: string;
  index: number;
  headTitle: string;
  body: React.ReactNode;
  children?: React.ReactNode
}

const Accordion = ({ id, target, index, headTitle, body, children }: AccordionProps) => {
  return (
    <div key={target} className="accordion mb-3" id={id}>
      {/* ACCORDION PARENT */}
      <div className="accordion-item p-0 m-0 ">
        <h2 className="accordion-header" id={id}>
          <button className="accordion-button bg-transparent px-3 py-2 shadow-none collapsed "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${target}`}
            aria-expanded="false"
            // aria-labelledby={id}
            aria-controls={`${target}`}>
            <p className=' text-primary fw-semibold small p-0 m-0' >{headTitle}</p>
          </button>
        </h2>
        <div id={`${target}`} className="accordion-collapse border-top collapse p-3 pb-0" aria-labelledby={`heading${index}`} data-bs-parent={id}>
          <div className='w-100 d-flex mb-2 row justify-content-between align-items-center p-0 m-0' >
            {/* PARENT CONTENT */}
            {body}
          </div>
          {/* INNER ACCORDION */}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion