import * as React from 'react';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useParams } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

import { Icon } from '../shared/Icon/Icon';
import { ToastMessage } from '../shared/Toast/Toast';
import { ToastContext } from '../../contexts/ToastContext';
import { FeasibilityContextValues } from '../../contexts/FeasibilityContext';
import { fetchGetJson, fetchPostJson } from '../../services/services';
import { feasibilityPostValuesInterface } from '../../interfaces/feasibilityInterfaces';
import Accordion from '../shared/Accordion/Accordion';
import { AccordionBody } from '../shared/Accordion/AccordionBody';

export const Feasibility: React.FunctionComponent = () => {
  const { toastMessage, setToastMessage } = React.useContext(ToastContext)
  const { feasibilityGetValues, setFeasibilityGetValues, feasibilityPostValues, setFeasibilityPostValues } = React.useContext(FeasibilityContextValues);
  const [username, setUsername] = React.useState<string>();
  const [didMount, setDidMount] = React.useState<boolean>(false);
  const [didGetToken, setDidGetToken] = React.useState<boolean>(false);
  const [token, setAccessToken] = React.useState<string>();
  const [showActionMesssage, setShowActionMesssage] = React.useState<string>("");
  const [finalPrice, setFinalPrice] = React.useState<feasibilityPostValuesInterface>({ ...feasibilityPostValues, totalPrice: 0 });
  const { id: bidId } = useParams();
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();

  React.useEffect(() => {
    if (isAuthenticated && !didGetToken) {
      setDidGetToken(true)
      const request = {
        scopes: ["User.Read"],
        account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
        setAccessToken(response.idToken);
      }).catch((e) => {
        instance.acquireTokenRedirect(request).then((response) => {
        });
      });
    }
  }, [isAuthenticated, didGetToken, accounts, instance])

  React.useEffect(() => {
    if (accounts.length > 0) {
      const currentAcountName = accounts[0] && accounts[0].username;
      setUsername(currentAcountName)
    }
  }, [accounts])

  React.useEffect(() => {
    if (token && !didMount) {
      setDidMount(true)
      fetchGetJson(`/b/bids/${bidId}`, token)
        .then(res => {
          if (res.error) {
            setShowActionMesssage("show")
            setToastMessage({ isError: true, message: "Wrong bid ID" });
          } else {
            let totalInitialCost = 0;
            setFeasibilityGetValues({ ...res })
            if (res.markets.length > 0) {
              res.markets.map((market: any) => {
                let targetNumberOfCompletes = market.targetNumberOfCompletes;
                let costPerInterview = market.costPerInterview === 'Unavailable' ? 0.0 : Number(market.costPerInterview);
                return totalInitialCost = totalInitialCost + (targetNumberOfCompletes * costPerInterview)
              })
            }
            setFeasibilityPostValues({ ...feasibilityPostValues, markets: [...res.markets] })
            setFinalPrice({ ...feasibilityPostValues, totalPrice: Number(totalInitialCost.toFixed(2)) })
          }
        })
        .catch((error) => setToastMessage({ isError: true, message: error?.message })
        )
    }
  }, [token, bidId, didMount, feasibilityGetValues, setFeasibilityGetValues, feasibilityPostValues, setFeasibilityPostValues, setToastMessage]);

  React.useEffect(() => {
    let totalCost = 0;
    let totalPTP = feasibilityPostValues.fullServiceCosts.programming + feasibilityPostValues.fullServiceCosts.translations + feasibilityPostValues.fullServiceCosts.dataProcessing
    let discount = feasibilityPostValues.discount
    feasibilityPostValues.markets.map((market: any) => {
      let targetNumberOfCompletes = market.feasibility;
      let costPerInterview = market.costPerInterview === 'Unavailable' ? 0.0 : Number(market.costPerInterview);
      return totalCost = totalCost + (targetNumberOfCompletes * costPerInterview)

    })
    setFinalPrice({ ...feasibilityPostValues, totalPrice: Number(totalCost.toFixed(2)) + totalPTP - discount })
  }, [feasibilityPostValues, setFeasibilityPostValues]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, marketName: string, inputType: string) => {
    let editedMakrets = feasibilityPostValues.markets.map((market: any) => {
      if (market.marketName === marketName) {
        if (inputType === "feasibility") {
          const totalCoast = Number(e.target.value) * market.costPerInterview
          return { ...market, feasibility: e.target.value !== "" ? Number(e.target.value) : 0, totalCost: totalCoast.toString() }
        } else {
          const totalCoast = Number(e.target.value) * market.feasibility
          return { ...market, costPerInterview: e.target.value, totalCost: totalCoast.toFixed(2).toString() }
        }
      }
      return market
    })
    setFeasibilityPostValues({ ...feasibilityPostValues, markets: [...editedMakrets] })
  }

  const onFinalise = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShowActionMesssage("show")
    fetchPostJson(`/b/bids/${bidId}/feasibility`, { ...feasibilityPostValues, ...finalPrice }, token)
      .then((res: any) => {
        if (res) {
          if (res.error) {
            setToastMessage({ isError: true, message: "Bid can not be finalized" });
          } else {
            setToastMessage({ message: "Finalise Bid successful" })
          }
        }
      })
      .catch((error: any) => setToastMessage({ isError: true, message: error }))
  }

  const surveyFrequency = (): string => {
    return `${feasibilityGetValues.surveyType === 1 ?
      feasibilityGetValues.trackerProjectDetails.frequency === "Weekly" ? "/week" :
        feasibilityGetValues.trackerProjectDetails.frequency === "Monthly" ? "/month" :
          "/quarter"
      : ""}`
  }

  const provideExclutionDuration = ({ exclusionDuration }): string => {
    return exclusionDuration === 0 ? "All time" :
      exclusionDuration === 1 ? "Last 6 months" :
        exclusionDuration === 2 ? "Last 3 months" :
          "Last month"
  }

  const provideExclutionStatus = ({ exclusions }): string => {
    return exclusions.map((status: number) => (
      status === 0 ? ["Entrants"] :
        status === 1 ? ["Completes"] :
          status === 2 ? ["Screen out"] :
            ["Quota fails"]
    )).join()
  }

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={() => <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        className="me-2"
      />}>
        {!isAuthenticated ? <div className="col-12 bg-walr-secondary min-vh-100 d-flex justify-content-center align-items-center flex-column"><p className="text-primary fs-5 mb-3">Working...</p><Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        /></div> :
          <div className="container-fluid">
            <div className="row ps-default ">
              <div className="col-12 py-3 px-4 d-flex w-100 justify-content-end  align-items-center bg-walr-secondar">
                <div>
                  <p className="btn bg-transparent border-gray py-1 px-3 rounded-pill">{username}</p>
                </div>
              </div>
              <div className="bg-white py-5 max-width-500">
                <form onSubmit={onFinalise}>
                  <p className="text-primary fw-bold display-7 m-0">Bid Summary</p>
                  <p className="text-primary fw-semibold fs-6 my-2">Overview</p>
                  <div className="rounded overflow-hidden mb-3 p-3 table-summary">
                    <table className="table table-borderless">
                      <caption className='caption-top text-primary bold py-0' >Survey information</caption>
                      <tbody>
                        <tr>
                          <td colSpan={2} className="w-50 pb-0 ps-0 text-muted">Name</td>
                          <td colSpan={2} className="pb-0 ps-0 text-primary">{feasibilityGetValues.surveyName}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="px-0 pb-0 text-muted">Requested by</td>
                          <td colSpan={2} className="ps-0 pb-0 pe-0 text-primary"><a href={`mailto:${feasibilityGetValues.email}`}>{feasibilityGetValues.email}</a> </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="px-0 pb-0 text-muted">Survey type</td>
                          <td colSpan={2} className="ps-0 pb-0 pe-0 text-primary">{feasibilityGetValues.surveyType}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="px-0 pb-0  text-muted">Project type</td>
                          <td colSpan={2} className="ps-0 pb-0 pe-0 text-primary">{feasibilityGetValues.serviceType}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="w-50 px-0 pb-0 text-muted">PII Data</td>
                          <td colSpan={2} className="w-50 px-0 pb-0 text-primary">{feasibilityGetValues.containsPersonalData ? "Yes" : "No"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="rounded overflow-hidden mb-3 p-3 table-summary">
                    <table className="table table-borderless">
                      <caption className='caption-top text-primary bold pt-0' >Survey details</caption>
                      <tbody>
                        {feasibilityGetValues.surveyType === 1 &&
                          <tr>
                            <td colSpan={2} className="w-50 pt-1 pb-0 ps-0 text-muted">Survey frequency</td>
                            <td colSpan={2} className="p-0 text-primary">{feasibilityGetValues.trackerProjectDetails.frequency}</td>
                          </tr>
                        }
                        <tr>
                          <td colSpan={2} className="w-50 pb-0 px-0 text-muted">Length of interview</td>
                          <td colSpan={2} className="px-0 pb-0 text-primary">{`${feasibilityGetValues.lengthOfInterview}${feasibilityGetValues.lengthOfInterview === 1 ? " minute" : " minutes"}`}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="pb-0 pe-0 ps-0 text-muted">Launch date</td>
                          <td colSpan={2} className="px-0 pb-0 text-primary">{feasibilityGetValues.launchDate.split('T')[0]}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="pb-0 pe-0 ps-0 text-muted">End date</td>
                          <td colSpan={2} className="pb-0 px-0 text-primary">{feasibilityGetValues.completeAsap ? "Complete as soon as possible" : feasibilityGetValues.targetEndDate.split('T')[0]}</td>
                        </tr>
                        {feasibilityGetValues.serviceType === 1 &&
                          <>
                            <tr>
                              <td colSpan={2} className="w-50 pe-0 ps-0 text-muted pb-0">Number of questions</td>
                              <td colSpan={2} className="w-50 px-0 pb-0 text-primary">{feasibilityGetValues.fullServiceProjectDetails.numberOfQuestions}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="w-50 pe-3 ps-0 pb-0 text-muted">Survey complexity</td>
                              <td colSpan={2} className="pb-0 px-0 text-primary">{feasibilityGetValues.fullServiceProjectDetails.surveyScripting}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="pb-0 pe-0 ps-0 text-muted">Data processing</td>
                              <td colSpan={2} className="px-0 pb-0 text-primary">{feasibilityGetValues.fullServiceProjectDetails.dataProcessingRequired ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="pb-0 pe-0 ps-0 text-muted">Translations</td>
                              <td colSpan={2} className="px-0 pb-0 text-primary">{feasibilityGetValues.fullServiceProjectDetails.translationsRequired ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        }
                        {feasibilityGetValues.surveyType === 1 &&
                          <>
                            <tr>
                              <td colSpan={2} className="pb-0 pe-0 ps-0  text-muted">Exclusion status</td>
                              <td colSpan={2} className="px-0 pb-0 text-primary">{provideExclutionStatus(feasibilityGetValues.trackerProjectDetails)}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="w-50 pe-0 ps-0 text-muted pb-0">Exclusion duration</td>
                              <td colSpan={2} className="w-50 px-0 pb-0 text-primary">{provideExclutionDuration(feasibilityGetValues.trackerProjectDetails)}</td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                  {
                    feasibilityGetValues.markets.map((market, index) => {
                      return (
                        <Accordion
                          id={`accordion${index}`}
                          target={`main${index}`}
                          index={index}
                          headTitle={market.marketName}
                          key={index}
                          body={<AccordionBody accordionType='MarketInfo' data={market} />}
                        >
                          {
                            market.interlockedQuotas.length ?
                              <Accordion
                                id={`accordion${'Interlocked Quotas'}`}
                                target={`innerInterlockedQuotas${index}`}
                                index={index}
                                headTitle="Interlocked Quotas"
                                body={<AccordionBody accordionType="Interlocked Quotas" data={market} />}
                              ></Accordion> : null
                          }
                          {
                            market.screening.length ?
                              <Accordion
                                id={`accordion${'Screening'}`}
                                target={`innerScreening${index}`}
                                index={index}
                                headTitle="Screening"
                                body={<AccordionBody accordionType="Screening" data={market} />}
                              ></Accordion> : null
                          }
                          {
                            market.quotas.length ?
                              <Accordion
                                id={`accordion${'Quotas'}`}
                                target={`innerQuotas${index}`}
                                index={index}
                                headTitle="Quotas"
                                body={<AccordionBody accordionType="Quotas" data={market} />}
                              ></Accordion> : null
                          }
                        </Accordion>
                      )
                    })
                  }
                  <div className="rounded overflow-hidden mb-3 p-3 border border-1 border-light bg-ultra-light">
                    <table className="table m-0 table-borderless">
                      <caption className='caption-top text-primary bold' >Markets</caption>
                      <thead>
                        <tr  >
                          <th className="w-40 p-0 small fw-light text-muted">Name</th>
                          <th className="w-15 p-0 small fw-light text-muted">IR</th>
                          <th className="w-15 p-0 small fw-light text-muted">Target</th>
                          <th className="w-15 p-0 small fw-light text-muted">Feasible</th>
                          <th className="w-15 p-0 small text-start fw-light text-muted">CPI</th>
                        </tr>
                      </thead>
                      {feasibilityPostValues.markets.map((market, index) => {
                        return (
                          <tbody key={index} >
                            <tr>
                              <th className="p-0 pt-3 small align-middle fw-normal text-primary">
                                <span className="d-flex h-100 align-items-center">
                                  <Icon type="globe" className="d-flex align-content-center me-1" />
                                  {market.marketName}
                                </span>
                              </th>
                              <td className="align-middle px-0 pb-0 pt-3 small text-primary">{market.incidenceRate}%</td>
                              <td className="align-middle px-0 pb-0 pt-3 small text-primary">{market.targetNumberOfCompletes}</td>
                              <td className="align-middle px-0 pb-0 pt-3 small text-start text-primary" >
                                <div className="w-75 border p-0 m-0 border-1 border-light rounded-1 d-flex justify-content-start align-items-center">
                                  <input
                                    type="number"
                                    value={market.feasibility.toString()}
                                    min={1}
                                    max={market.targetNumberOfCompletes}
                                    className={`form-control-sm w-100 ps-1 pe-0 border-0 text-primary m-0 py-0`}
                                    required
                                    onChange={(e) => handleInputChange(e, market.marketName, "feasibility")}
                                  />
                                </div>
                              </td>
                              <td className="px-0 pb-0 pt-3 text-center">
                                <div className="w-100 border p-0 m-0 border-1 border-light rounded-1 d-flex justify-content-start align-items-center">
                                  <p className="bg-white text-muted small py-0 my-0 ps-1 ">£</p>
                                  <input
                                    type="number"
                                    min={0.1}
                                    step="0.01"
                                    className="form-control-sm w-100 ps-1 pe-0 border-0 text-primary m-0 py-0"
                                    value={market.costPerInterview.toString() === 'Unavailable' ? "0.0" : market.costPerInterview}
                                    onChange={(e) => handleInputChange(e, market.marketName, "costPerInterview")} />
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        )
                      })}
                    </table>
                  </div>
                  <div className="rounded bg-ultra-light overflow-hidden border border-1 border-light p-3 mb-4">
                    <div className="row mb-3">
                      <div className="col-4">
                        <label htmlFor="programmingCost" className="form-label fs-7">Programming cost</label>
                        <div className={`d-flex align-items-center border border-1 border-light rounded-2 ps-1 ${!feasibilityGetValues.fullServiceProjectDetails?.surveyScripting === null && "bg-light"}`}>
                          <span className="bg-trasparent text-primary small border border-0 p-1">£</span>
                          <input
                            id="programmingCost"
                            type="number"
                            className="form-control ps-1 border-0 text-primary m-0"
                            value={feasibilityPostValues.fullServiceCosts.programming !== 0 ? feasibilityPostValues.fullServiceCosts.programming.toString() : "0"}
                            disabled={feasibilityGetValues.fullServiceProjectDetails?.surveyScripting === null}
                            onChange={(e) => setFeasibilityPostValues({
                              ...feasibilityPostValues,
                              fullServiceCosts: { ...feasibilityPostValues.fullServiceCosts, programming: e.target.value === "0" ? 0 : Number(e.target.value) }
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <label htmlFor="translationsCost" className="form-label fs-7">Translations cost</label>
                        <div className={`d-flex align-items-center border border-1 border-light rounded-2 ps-1 ${!feasibilityGetValues.fullServiceProjectDetails?.translationsRequired && "bg-ultra-light"}`}>
                          <span className="bg-trasparent text-primary small border border-0 p-1">£</span>
                          <input
                            id="translationsCost"
                            type="number"
                            className="form-control ps-1 border-0 text-primary m-0"
                            value={feasibilityPostValues.fullServiceCosts.translations !== 0 ? feasibilityPostValues.fullServiceCosts.translations.toString() : "0"}
                            disabled={!feasibilityGetValues.fullServiceProjectDetails?.translationsRequired}
                            onChange={(e) => setFeasibilityPostValues({
                              ...feasibilityPostValues,
                              fullServiceCosts: { ...feasibilityPostValues.fullServiceCosts, translations: e.target.value === "0" ? 0 : Number(e.target.value) }
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <label htmlFor="dataProcessingCost" className="form-label fs-7">Data processing cost</label>
                        <div className={`d-flex align-items-center border border-1 border-light rounded-2 ps-1 ${!feasibilityGetValues.fullServiceProjectDetails?.dataProcessingRequired && "bg-ultra-light"}`}>
                          <span className="bg-transparent text-primary small border border-0 p-1">£</span>
                          <input
                            id="dataProcessingCost"
                            type="number"
                            className="form-control ps-1 border-0 text-primary m-0"
                            value={feasibilityPostValues.fullServiceCosts.dataProcessing !== 0 ? feasibilityPostValues.fullServiceCosts.dataProcessing.toString() : "0"}
                            disabled={!feasibilityGetValues.fullServiceProjectDetails?.dataProcessingRequired}
                            onChange={(e) => setFeasibilityPostValues({
                              ...feasibilityPostValues,
                              fullServiceCosts: { ...feasibilityPostValues.fullServiceCosts, dataProcessing: e.target.value === "0" ? 0 : Number(e.target.value) }
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12">
                        <label htmlFor="feasibilityDiscountAmount" className="form-label fs-7">Discount</label>
                        <div className="d-flex align-items-center border border-1 border-light rounded-2 ps-1">
                          <span className="bg-white text-primary small border border-0 p-1">£</span>
                          <input
                            id="feasibilityDiscountAmount"
                            type="number"
                            className="form-control ps-1 border-0 text-primary m-0"
                            value={feasibilityPostValues.discount !== 0 ? feasibilityPostValues.discount.toString() : feasibilityGetValues.discount === null ? 0 : feasibilityGetValues.discount.toString()}
                            onChange={(e) => setFeasibilityPostValues({ ...feasibilityPostValues, discount: e.target.value === "" ? 0 : Number(e.target.value) })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <label htmlFor="feasibilityComment" className="form-label fs-7">Comment</label>
                        <textarea
                          className="form-control"
                          id="feasibilityComment"
                          rows={3}
                          placeholder="Add any further details here"
                          value={feasibilityPostValues.comment}
                          onChange={(e) => setFeasibilityPostValues({ ...feasibilityPostValues, comment: e.target.value })}></textarea>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="text-primary mb-0 fw-semibold">Total estimated price</p>
                      </div>
                      <div className="col-6">
                        <p className="text-primary text-end mb-0 fw-semibold">£ {finalPrice.totalPrice ? finalPrice.totalPrice.toFixed(2) : 0}{surveyFrequency()}</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button type="submit" value="Submit" disabled={toastMessage.message?.includes("successful")} className="btn btn-primary m-0 py-2 w-100 d-flex justify-content-center align-items-center">
                      <small>Finalise Bid</small>
                    </button>
                  </div>
                </form>
              </div>
            </div >
            <div>
            </div>
            {toastMessage.message &&
              <ToastMessage
                show={showActionMesssage}
                setShow={setShowActionMesssage}
                message={toastMessage.message}
                isError={toastMessage.isError}
              />}
          </div>
        }
      </MsalAuthenticationTemplate>
    </>
  )
};