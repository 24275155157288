import * as React from 'react';
import { Spinner } from "react-bootstrap";

import { Icon } from '../../shared/Icon/Icon';
import { BiddingContextValues } from '../../../contexts/BiddingContext';
import { NavigationContextValues } from '../../../contexts/NavigationContext';
import { ToastContext } from '../../../contexts/ToastContext';
import { fetchPost } from '../../../services/services';
import { ToastMessage } from '../../shared/Toast/Toast';

interface BidProposalProps {
}

export const BidProposal: React.FunctionComponent<BidProposalProps> = (props) => {
  const { toastMessage, setToastMessage } = React.useContext(ToastContext)
  const [showActionMesssage, setShowActionMesssage] = React.useState<string>("");
  const { surveyInfoValues, finalizeData, setFinalizeData, bidLoading, setBidLoading, userEmail } = React.useContext(BiddingContextValues);
  const { setCurrentNavPage } = React.useContext(NavigationContextValues);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await fetchPost(`/b/bids/${finalizeData.id}/submit`)
      .then((res: any) => {
        if (res.error) {
          setToastMessage({ isError: true, message: res.error });
        } else {
          setFinalizeData({ ...finalizeData, bidReferenceNumber: res.bidReferenceNumber })
          setCurrentNavPage("BidReference");
        }
      })
      .catch((error: any) => { console.log(error); })
      .finally(() => {
        setBidLoading(false)
      })
  }

  return <div className="container-fluid">
    <div className={`row ps-default ${bidLoading ? "h-75" : ""}`}>
      <div className="col-12 d-flex justify-content-end align-items-center pt-4">
        <p className="p-0 m-0 me-2 text-primary">{userEmail}</p>
        <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
        <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>
      {bidLoading ?
        <div className="w-100 h-100 max-width-500 d-flex justify-content-center align-items-center p-0"><Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        />
        </div>
        :
        <div className="w-100 max-width-500 py-5 mt-2 ps-3 pe-0">
          <p className="display-7 text-primary fw-bold mb-2">Here’s your bid proposal</p>
          <p className="text-muted mb-4">Please review your bid proposal and your estimated cost.</p>
          <form onSubmit={onSubmit}>
            <div className="rounded table-summary overflow-hidden mb-3">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td colSpan={2} className="w-50 ps-3 pe-0 py-3 small text-muted">Markets</td>
                    <td colSpan={1} className="w-25 ps-0 pe-0 py-3 small text-muted">Completes</td>
                    <td colSpan={1} className="w-25 ps-0 pe-0 py-3 small text-muted">Incidence rate</td>
                  </tr>
                  {surveyInfoValues.selectedMarkets.length > 0 &&
                    surveyInfoValues.selectedMarkets.map(market => <tr key={market.MarketName}>
                      <td colSpan={2} className="w-50 ps-3 pe-0 small"><span className="d-flex align-items-center text-primary"><Icon type="globe" className="d-flex align-content-center me-1" />{market.MarketName}</span></td>
                      <td colSpan={1} className="w-25 ps-0 pe-0 small text-primary">{market.TargetNumberOfCompletes}</td>
                      <td colSpan={1} className="w-25 ps-0 pe-0 small text-primary">{market.IncidenceRate}%</td>
                    </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <div className="rounded table-summary overflow-hidden mb-3">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td colSpan={2} className="w-50 ps-3 pe-0 pt-3 bold text-primary small">Market estimates</td>
                    <td colSpan={1} className="w-25 ps-0 pe-0 pt-3 small text-muted">CPI</td>
                    <td colSpan={1} className="w-25 ps-0 pe-0 pt-3 small text-muted">Total cost</td>
                  </tr>
                  {finalizeData.markets !== undefined && finalizeData.markets.map(market => {
                    return <tr key={market.marketName}>
                      <td colSpan={2} className="w-50 ps-3 pe-0 small text-muted"><span className="d-flex align-items-center text-primary"><Icon type="globe" className="d-flex align-content-center me-1" />{market.marketName}</span></td>
                      <td colSpan={1} className="w-25 ps-0 pe-0 small text-primary">{market.costPerInterview === "Unavailable" ? "–" : `£${market.costPerInterview}`}</td>
                      <td colSpan={1} className="w-25 ps-0 pe-0 small text-primary">{market.totalCost === "Unavailable" ? "–" : `£${market.totalCost}`}</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="rounded table-summary overflow-hidden mb-3">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" colSpan={4} className="border-0 py-3 ps-3 pe-1 bold text-primary small">Your estimate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2} className="w-50 ps-3 pe-0 small text-muted">Subtotal</td>
                    <td colSpan={1} className="w-50 ps-0 pe-3 small text-end text-primary">{finalizeData.totalEstimatedCost === "Unavailable" ? "To be decided" : `£${finalizeData.totalEstimatedCost}`}</td>
                  </tr>
                  {finalizeData.discount !== null ?
                    <tr>
                      <td colSpan={2} className="ps-3 pe-3 small text-walr">Discount</td>
                      <td colSpan={1} className="ps-3 pe-3 small text-end text-walr">{finalizeData.discount}</td>
                    </tr> : null
                  }
                  <tr>
                    <td colSpan={2} className="ps-3 pe-3 py-1 pb-3 small text-muted">Estimated cost</td>
                    <td colSpan={1} className="ps-0 pe-3 py-1 pb-3 small text-end text-primary">
                      {finalizeData.discount !== null ? (parseInt(finalizeData.totalEstimatedCost) - parseInt(finalizeData.discount)) :
                        finalizeData.totalEstimatedCost === "Unavailable" ? "To be decided" : `£${finalizeData.totalEstimatedCost}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="d-block">
              <button type="submit" className="btn btn-walr text-primary py-2 w-100">Submit bid proposal</button>
            </div>
            <p className="legal text-muted mt-3 d-flex justify-content-center">
              This is an estimate cost, your final price may vary based on field metrics.
            </p>
          </form>
        </div>
      }
      {toastMessage.isError &&
        <ToastMessage
          show={showActionMesssage}
          setShow={setShowActionMesssage}
          message={toastMessage.message}
          isError={toastMessage.isError}
        />}
    </div>
  </div>
};