const BASE_URL = process.env.REACT_APP_API_URL

export const fetchGetJson = (path: string, token?: string) => {
  try {

    return fetch(`${BASE_URL}${path}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
      }),
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}

export const fetchPost = (path: string) => {
  try {
    return fetch(`${BASE_URL}${path}`, {
      method: 'POST',
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}

export const fetchPostFile = (path: string, file: FormData) => {
  try {
    return fetch(`${BASE_URL}${path}`, {
      method: 'POST',
      body: file
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}

export const fetchPostJson = (path: string, body: unknown, token?: string) => {
  try {
    return fetch(`${BASE_URL}${path}`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
      }),
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => data)
      .catch(error => {
        return error;
      })
  } catch (error) {
    return error
  }
}