import * as React from 'react';

import { BidProposal } from './BidProposal/BidProposal';
import { Summary } from './Summary/Summary';
import { NavigationContextValues } from '../../contexts/NavigationContext';

interface ReviewProps {
}

export const Review: React.FunctionComponent<ReviewProps> = (props) => {
  const { currentNavSubPage } = React.useContext(NavigationContextValues)
  return <>
    {currentNavSubPage === "Summary" ? <Summary /> :
      currentNavSubPage === "BidProposal" ? <BidProposal /> : null
    }
  </>;
};