import * as React from "react";
import { Spinner } from "react-bootstrap";

// import { Icon } from "../../shared/Icon/Icon";
import { ToastMessage } from "../../shared/Toast/Toast";
import { CustomAudienceModal } from "../../shared/CustomAudienceModal/CustomAudienceModal";
import { ToastContext } from "../../../contexts/ToastContext";
import { BiddingContextValues } from "../../../contexts/BiddingContext";
import { NavigationContextValues } from "../../../contexts/NavigationContext";
import { fetchPostJson } from "../../../services/services";
interface QuotasTargetingProps {
}

export const QuotasTargeting: React.FunctionComponent<QuotasTargetingProps> = (props) => {
  const [showActionMesssage, setShowActionMesssage] = React.useState<string>("");
  const { setCurrentNavSubPage, setCurrentNavPage } = React.useContext(NavigationContextValues);
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const { toastMessage, setToastMessage } = React.useContext(ToastContext)
  // const { quotasTargetValues, setQuotasTargetValues, userEmail } = React.useContext(BiddingContextValues);
  const { userEmail, surveyInfoValues, adHocValues, trackerValues, fullService, quotasTargetValues, setQuotasTargetValues, projectType, setBidLoading, setFinalizeData, bidLoading, customAudienceModalValues, setCustomAudienceModalValues } = React.useContext(BiddingContextValues);
  const { selectedMarkets, interviewLength } = surveyInfoValues

  const generalBody = {
    SurveyName: surveyInfoValues.surveyName,
    SurveyType: surveyInfoValues.surveyType,
    NationalRepresentativeSample: quotasTargetValues.quotasNatRep,
    GeneralPopulationSample: quotasTargetValues.quotasGenPop,
    Email: userEmail,
    ServiceType: projectType,
    Markets: quotasTargetValues.quotasCustomAudience ? customAudienceModalValues : selectedMarkets,
    ContainsPersonalData: surveyInfoValues.containsPersonalData === "Yes" ? true : false,
    LengthOfInterview: surveyInfoValues.interviewLength,
    AttachedFilename: quotasTargetValues.quotasTargetFile,
    TargetingDetails: quotasTargetValues.quotasTargetDetails
  }

  const fullServiceBody = {
    FullServiceProjectDetails: {
      SurveyScripting: fullService.programmingComplexity,
      NumberOfQuestions: fullService.numberOfQuestions,
      DataProcessingRequired: fullService.dataProcess,
      TranslationsRequired: fullService.translation
    }
  }

  const adHocBody = {
    LaunchDate: adHocValues.adHocLaunchDate,
    TargetEndDate: adHocValues.adHocTargetEndDate !== "" ? adHocValues.adHocTargetEndDate : null,
    completeAsap: surveyInfoValues.completeAsap
  }

  const trackerBody = {
    LaunchDate: trackerValues.trackerLaunchDate,
    TargetEndDate: trackerValues.trackerTargetEndDate !== "" ? trackerValues.trackerTargetEndDate : null,
    completeAsap: surveyInfoValues.completeAsap,
    TrackerProjectDetails: {
      Frequency: trackerValues.trackerFrequency,
      ExclusionDuration: trackerValues.trackerExclusionDuration,
      Exclusions: trackerValues.trackerExclusionStatus.map(item => item.value),
    }
  }


  const handleModalVisibility = () => {
    setModalVisibility(!modalVisibility)
    if (!modalVisibility) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "unset"
    }
  }

  const handleAudienceType = (audienceType: string) => {
    if (audienceType === "GenPop") {
      if (quotasTargetValues.quotasGenPop) {
        setQuotasTargetValues({ ...quotasTargetValues, quotasGenPop: false })
      } else {
        setQuotasTargetValues({ ...quotasTargetValues, quotasGenPop: true, quotasNatRep: false, quotasCustomAudience: false })
      }
    } else if (audienceType === "NatRep") {
      if (quotasTargetValues.quotasNatRep) {
        setQuotasTargetValues({ ...quotasTargetValues, quotasNatRep: false })
      } else {
        setQuotasTargetValues({ ...quotasTargetValues, quotasNatRep: true, quotasGenPop: false, quotasCustomAudience: false })
      }
    }
    else if (audienceType === "CustomAudience") {
      setQuotasTargetValues(prev => ({ ...quotasTargetValues, quotasNatRep: false, quotasGenPop: false, quotasCustomAudience: !prev.quotasCustomAudience }))
    }
  }

  const onSubmitHandler = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (quotasTargetValues.quotasCustomAudience && !modalVisibility) {
      handleModalVisibility()
    } else {
      setBidLoading(true)
      if (projectType === "FullService") {
        if (surveyInfoValues.surveyType === "AdHoc") {
          fetchPostJson("/b/bids", { ...generalBody, ...fullServiceBody, ...adHocBody })
            .then((res: any) => {
              if (res.error) {
                setToastMessage({ isError: true, message: res.error });
              } else {
                setFinalizeData({ ...res })
                setCurrentNavPage("Review");
                setCurrentNavSubPage("Summary");
              }
            })
            .catch((error: any) => { console.log(error); })
            .finally(() => {
              setBidLoading(false)
            })
        } else {
          fetchPostJson("/b/bids", { ...generalBody, ...fullServiceBody, ...trackerBody })
            .then((res: any) => {
              if (res.error) {
                setToastMessage({ isError: true, message: res.error });
              } else {
                setFinalizeData({ ...res })
                setCurrentNavPage("Review");
                setCurrentNavSubPage("Summary");
              }
            })
            .catch((error: any) => { console.log(error); })
            .finally(() => {
              setBidLoading(false)
            })
        }
      } else {
        if (surveyInfoValues.surveyType === "AdHoc") {
          fetchPostJson("/b/bids", { ...generalBody, ...adHocBody })
            .then((res: any) => {
              if (res.error) {
                setToastMessage({ isError: true, message: res.error });
              } else {
                setFinalizeData({ ...res })
                setCurrentNavPage("Review");
                setCurrentNavSubPage("Summary");
              }
            })
            .catch((error: any) => { console.log(error); })
            .finally(() => {
              setBidLoading(false)
            })
        }
        else {
          fetchPostJson("/b/bids", { ...generalBody, ...trackerBody })
            .then((res: any) => {
              if (res.error) {
                setToastMessage({ isError: true, message: res.error });
              } else {
                setFinalizeData({ ...res })
                setCurrentNavPage("Review");
                setCurrentNavSubPage("Summary");
              }
            })
            .catch((error: any) => { console.log(error); })
            .finally(() => {
              setBidLoading(false)
            })
        }
      }
    }
  }

  return <div className="container-fluid">
    <div className="row ps-default">
      <div className="col-12 d-flex justify-content-end align-items-center pt-4">
        <p className="p-0 m-0 me-2">{userEmail}</p>
        <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
        <a className="btn btn-secondary border-primary rounded-pill small px-3 me-5" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>
      <div className="w-100 max-width-500 py-5 mt-2 pe-2">
        <p className="display-7 text-primary fw-bold mb-4">Choose your targeting</p>
        <form onSubmit={onSubmitHandler}>
          <div className="d-flex justify-content-start flex-column mb-4">
            <div
              className={`form-check d-flex border border-1 rounded p-3 mb-3 ${quotasTargetValues.quotasNatRep ? "border-primary" : "border-muted"}`}
              onClick={() => handleAudienceType("NatRep")}>
              <input
                className="form-check-input p-2 ms-0 me-3"
                type="radio"
                name="quotaAudienceType"
                id="nationalRepresentativeSample"
                readOnly
                checked={quotasTargetValues.quotasNatRep}
              />
              <label className="form-check-label lh-sm" htmlFor="quotaAudienceType">
                <span className="d-flex text-primary bold mb-1">National representative</span>
                <span className="text-muted">A sample from our panel is balanced in terms of age, gender, social grade, education, and region.</span>
              </label>
            </div>
            <div
              className={`form-check d-flex border border-1 rounded p-3 mb-3 ${quotasTargetValues.quotasGenPop ? "border-primary" : "border-muted"}`}
              onClick={() => handleAudienceType("GenPop")}>
              <input
                className="form-check-input p-2 ms-0 me-3"
                type="radio"
                name="quotaAudienceType"
                id="nationalRepresentativeSample"
                readOnly
                checked={quotasTargetValues.quotasGenPop}
              />
              <label className="form-check-label lh-sm" htmlFor="quotaAudienceType">
                <span className="d-flex text-primary bold mb-1">General Population</span>
                <span className="text-muted">A sample based population of individuals balanced in terms of age, gender, social grade, education, and region.</span>
              </label>
            </div>
            <div
              className={`form-check border border-1 rounded p-3 d-flex ${quotasTargetValues.quotasCustomAudience ? "border-primary" : "border-muted"}`}
              onClick={() => handleAudienceType("CustomAudience")}>
              <input
                className="form-check-input p-2 ms-0 me-3"
                type="radio"
                name="customAudience"
                id="customAudience"
                readOnly
                checked={quotasTargetValues.quotasCustomAudience}
              />
              <label className="form-check-label lh-sm" htmlFor="quotaAudienceType">
                <span className="d-flex text-primary bold mb-1">Custom Audience</span>
                <span className="text-muted">Build your own audience by utilizing numerous of targeting variables.</span>
              </label>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" value="Submit" className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
              {bidLoading ? <><Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
                <span>Processing...</span>
              </> : "Continue"}
            </button>
          </div>
        </form>
      </div>
    </div >
    {toastMessage.isError &&
      <ToastMessage
        show={showActionMesssage}
        setShow={setShowActionMesssage}
        message={toastMessage.message}
        isError={toastMessage.isError}
      />}
    {modalVisibility && <CustomAudienceModal handleModalVisibility={handleModalVisibility} interviewLength={interviewLength} selectedMarkets={selectedMarkets} userEmail={userEmail} setCustomAudienceModalValues={setCustomAudienceModalValues} customAudienceModalValues={customAudienceModalValues} onSubmitHandler={onSubmitHandler} />}
  </div >;
};