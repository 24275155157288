export const surveyTooltips = {
  marketsToInclude: "Markets to include",
  programmingComplexity: <><ul className="ps-2 pe-1 py-1 m-0">
    <li className="mb-1">Basic Scripting, no complex routing considered nor speciality scripting, i.e. Heat Maps, Conjoint, Shelf test etc.</li>
    <li className="mb-1">Moderate scripting, multiple routings, simple algorithms, images, multi markets with similar conditions.</li>
    <li>Complex routings, multiple markets; varying conditions, shelf test, heat maps, conjoint, max diff, etc.</li>
  </ul></>,
  dataProcessing: "This is dataprocessing tooltip",
  translations: "This is translations tooltip",
  natRep: "National representative spread of sample across the market",
  genPop: "General population a broad range of sample with no hard demographic quotes",
  fileUpload: "This is file upload tooltip",
}