import * as React from 'react';
import { Spinner } from "react-bootstrap";

import { Icon } from '../../shared/Icon/Icon';
import { BiddingContextValues } from '../../../contexts/BiddingContext';
import { NavigationContextValues } from '../../../contexts/NavigationContext';
import { fetchPost, fetchPostFile } from '../../../services/services';
import Accordion from '../../shared/Accordion/Accordion';
import { AccordionBody } from '../../shared/Accordion/AccordionBody';
import { ToastContext } from '../../../contexts/ToastContext';
import { ToastMessage } from '../../shared/Toast/Toast';

interface SummaryProps {
}

export const Summary: React.FunctionComponent<SummaryProps> = (props) => {
  const [fileFail, setFileFail] = React.useState({ failToUpload: false, message: "" })
  const { toastMessage, setToastMessage } = React.useContext(ToastContext)
  const [showActionMesssage, setShowActionMesssage] = React.useState<string>("");
  const { setCurrentNavSubPage, setCurrentNavPage } = React.useContext(NavigationContextValues);
  const { userEmail, surveyInfoValues, adHocValues, trackerValues, fullService, quotasTargetValues, finalizeData, projectType, bidLoading, setBidLoading, setFinalizeData } = React.useContext(BiddingContextValues);
  const regexEmptySpace = new RegExp(/([A-Z0-9])/g)

  // const generalBody = {
  //   SurveyName: surveyInfoValues.surveyName,
  //   SurveyType: surveyInfoValues.surveyType,
  //   NationalRepresentativeSample: quotasTargetValues.quotasNatRep,
  //   GeneralPopulationSample: quotasTargetValues.quotasGenPop,
  //   Email: userEmail,
  //   ServiceType: projectType,
  //   Markets: surveyInfoValues.selectedMarkets,
  //   ContainsPersonalData: surveyInfoValues.containsPersonalData,
  //   LengthOfInterview: surveyInfoValues.interviewLength
  // }

  // const fullServiceBody = {
  //   FullServiceProjectDetails: {
  //     SurveyScriptingType: fullService.programmingComplexity,
  //     NumberOfQuestions: fullService.numberOfQuestions,
  //     DataProcessingRequired: fullService.dataProcess,
  //     TranslationsRequired: fullService.translation
  //   }
  // }

  React.useEffect(() => {
    if (quotasTargetValues.quotasTargetData) {
      let data = new FormData()
      data.append("bidId", finalizeData.id)
      data.append("file", quotasTargetValues.quotasTargetData)
      const postFile = async () => {
        const postFileResponse = await fetchPostFile(`/fileupload`, data)
        if (postFileResponse.error) {
          setFileFail({ failToUpload: true, message: postFileResponse.error })
        }
        setBidLoading(false)
      }
      postFile()
    }
  }, [finalizeData.id, setBidLoading, quotasTargetValues.quotasTargetData])

  const onFinilize = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!fileFail.failToUpload) {
      fetchPost(`/b/bids/${finalizeData.id}/submit`)
        .then((res: any) => {
          if (res.error) {
            setToastMessage({ isError: true, message: res.error });
          } else {
            setFinalizeData({ ...finalizeData, bidReferenceNumber: res.bidReferenceNumber })
            setCurrentNavPage("BidReference");
          }
        })
        .catch((error: any) => { console.log(error); })
        .finally(() => {
          setBidLoading(false)
        })

    } else {
      setShowActionMesssage("show");
      setToastMessage({ isError: true, message: fileFail.message })
    }
  }

  const handleEdit = (targetNavPage: string, targetNavSubPage: string) => {
    setCurrentNavPage(targetNavPage)
    setCurrentNavSubPage(targetNavSubPage)
  }

  // const oneStringCaracterMarket = (market: string) => {
  //   if (market.length > 0) {
  //     return market.replace(/\s/g, '')
  //   } else {
  //     return market
  //   }
  // }

  const estimatedTotalPrice = ({ totalEstimatedCost }): string => {
    if (totalEstimatedCost === "Unavailable" || projectType === "FullService") {
      return "To be decided"
    } else {
      return `£ ${Number(totalEstimatedCost).toFixed(2)}${surveyInfoValues.surveyType === "Tracker" ?
        trackerValues.trackerFrequency === "Weekly" ? "/week" :
          trackerValues.trackerFrequency === "Monthly" ? "/month" :
            "/quarter"
        : ""}`
    }
  }

  const estimatedMarketPrice = ({ totalCost }): string => {
    if (totalCost === "Unavailable") {
      return "To be decided"
    } else {
      return `£ ${Number(totalCost).toFixed(2)}${surveyInfoValues.surveyType === "Tracker" ?
        trackerValues.trackerFrequency === "Weekly" ? "/week" :
          trackerValues.trackerFrequency === "Monthly" ? "/month" :
            "/quarter"
        : ""}`
    }
  }


  return <div className="container-fluid">
    <div className={`row ps-default ${bidLoading ? "h-75" : ""}`}>
      <div className="col-12 d-flex justify-content-end align-items-center pt-4">
        <p className="p-0 m-0 me-2 text-primary">{userEmail}</p>
        <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
        <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>

      {bidLoading ?
        <div className="w-100 h-100 max-width-500 d-flex justify-content-center align-items-center p-0">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
        </div>
        : <div className="w-100 max-width-500 py-5 mt-2 pe-2">
          <p className="display-7 text-primary fw-bold mb-3">Review and finish</p>
          <p className="text-muted mb-4">Kindly go through the summary of your bid request and the estimated cost for each market that you have chosen.</p>
          <form onSubmit={onFinilize}>
            <div className="rounded table-summary bg-transparent overflow-hidden mb-3">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" colSpan={3} className="pt-3 ps-3 pe-1 text-primary bold w-100">Survey information</th>
                    <th className="text-end pb-0 pt-3 pe-3 ps-1">
                      <button className="btn btn-transparent align-items-center d-flex border-0" onClick={() => handleEdit("ProjectDetails", "SurveyInformation")}>
                        <Icon type="dashboard-edit" className="fill-primary" /> <span className="d-flex flex-row text-primary regular">Edit</span>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={1} className="w-50 ps-3 pe-3 py-1 text-muted">Name</td>
                    <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">{surveyInfoValues.surveyName}</td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="ps-3 pe-3 py-1 text-muted">Survey type</td>
                    <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">{surveyInfoValues.surveyType}</td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="ps-3 pe-3 py-1 text-muted">Project type</td>
                    <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">{projectType.replace(regexEmptySpace, ' $1')}</td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="ps-3 pe-3 pt-1 pb-3 text-muted">Collect PII</td>
                    <td colSpan={2} className="ps-0 pe-3 pt-1 pb-3 all text-primary">{surveyInfoValues.containsPersonalData}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="rounded table-summary bg-transparent overflow-hidden pb-3 mb-3">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" colSpan={3} className="pt-3 ps-3 pe-1 text-primary bold w-100">Survey details</th>
                    <th className="text-end pb-0 pe-3 ps-1">
                      <button className="btn btn-transparent align-items-center d-flex border-0" onClick={() => handleEdit("ProjectDetails", surveyInfoValues.surveyType)}>
                        <Icon type="dashboard-edit" className="fill-primary" /> <span className="d-flex flex-row text-primary regular">Edit</span>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2} className="w-50 ps-3 pe-3 py-1 text-muted">Length of interview</td>
                    <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">{surveyInfoValues.interviewLength} minutes</td>
                  </tr>
                  {(surveyInfoValues.surveyType === "Tracker" && trackerValues.trackerFrequency !== "") &&
                    <tr>
                      <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Survey frequency</td>
                      <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">
                        {trackerValues.trackerFrequency}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Launch date</td>
                    <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">
                      {surveyInfoValues.surveyType === "AdHoc" ? adHocValues.adHocLaunchDate : trackerValues.trackerLaunchDate}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">End date</td>
                    <td colSpan={2} className="ps-0 pe-0 py-1 text-primary">
                      {surveyInfoValues.completeAsap ? "Complete as soon as possible" : surveyInfoValues.surveyType === "AdHoc" ? adHocValues.adHocTargetEndDate : trackerValues.trackerTargetEndDate}
                    </td>
                  </tr>
                  {projectType === 'FullService' &&
                    <tr>
                      <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Number of questions</td>
                      <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">{fullService.numberOfQuestions}</td>
                    </tr>
                  }
                  {surveyInfoValues.surveyType === "Tracker" &&
                    <>
                      <tr>
                        <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Exclusion status</td>
                        <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">
                          {trackerValues.trackerExclusionStatus.map(item => item.label).join()}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className={`ps-3 pe-3 pt-1 py-1 text-muted`}>
                          Exclusion duration
                        </td>
                        <td colSpan={2} className={`ps-0 pe-3 py-1 text-primary`}>
                          {trackerValues.trackerExclusionDuration.replace(regexEmptySpace, ' $1')}
                        </td>
                      </tr>
                    </>
                  }
                  {projectType === "FullService" &&
                    <>
                      <tr>
                        <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Scripting complexity</td>
                        <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">
                          {fullService.programmingComplexity && fullService.programmingComplexity}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="ps-3 pe-3 py-1 text-muted">Required data processing</td>
                        <td colSpan={2} className="ps-0 pe-3 py-1 text-primary">
                          {fullService.dataProcess ? "Yes" : "No"}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className={`ps-3 pe-3 py-1 text-muted`}>Required translations</td>
                        <td colSpan={2} className={`ps-0 pe-3 py-1 text-primary`}>
                          {fullService.translation ? "Yes" : "No"}
                        </td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>
            {
              finalizeData.markets.map((market, index) => {
                return (
                  <div className='review-markets'>
                    <Accordion
                      key={index}
                      id={`accordion${index}`}
                      target={`main${index}`}
                      index={index}
                      headTitle={market.marketName}
                      body={<AccordionBody accordionType='MarketInfo' data={market} />}
                    >
                      {/*TODO: UNCOMMENT AND HANDLE DATA WHEN QUOTA TARGETING IS PROVIDETED*/}
                      {/* {
                      ["Screening", "Quotas", "Interlocked Quotas"].map((item, index) => {
                        return (
                          <Accordion
                            id={`accordion${item}`}
                            target={`inner${oneStringCaracterMarket(market.MarketName)}${index}`}
                            index={index}
                            headTitle={item}
                            body={<AccordionBody accordionType={item} data={market} handleEdit={handleEdit} />}
                          ></Accordion>
                        )
                      })
                    } */}
                    </Accordion>
                  </div>
                )
              })
            }
            <div className="rounded border bg-ultra-light overflow-hidden p-3 mb-3 review-estimate">
              <p className=' text-primary bold' >Your estimate</p>
              <div>
                {
                  finalizeData.markets.map((market, index) => {
                    return (
                      <div key={index} >
                        <div className='w-100 d-flex mb-2 justify-content-between align-items-center' >
                          <p className="p-0 m-0 text-primary p-0">{market.marketName}</p>
                          <p className='p-0 m-0 text-primary p-0 m-0' >{estimatedMarketPrice(market)}</p>
                        </div>
                      </div>
                    )
                  })
                }
                {projectType === "FullService" &&
                  <>
                    <hr className='my-3' />
                    {["Survey complexity", "Data processing", "Translations", "Discount"].map((title, index) => {
                      return (
                        <div key={index}>
                          <div className='w-100 d-flex justify-content-between align-items-center' >
                            <p className={`p-0 m-0 mb-2 ${title === "Discount" && "text-walr-dark"} `}>{title}</p>
                            <p className='text-primary p-0 m-0 mb-2' ><small>-</small></p>
                          </div>
                        </div>
                      )
                    })}
                  </>
                }
                <hr className='mb-3' />
                <div>
                  <div className='w-100 d-flex mb-2 justify-content-between align-items-center' >
                    <p className="p-0 m-0 fw-bold" >Estimated cost</p>
                    <p className='text-primary fw-bold p-0 m-0' >{estimatedTotalPrice(finalizeData)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-grid">
              <button type="submit" value="Submit" className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
                Get offer from Walr
              </button>
            </div>
          </form>
        </div>
      }
    </div >
    {toastMessage.isError &&
      <ToastMessage
        show={showActionMesssage}
        setShow={setShowActionMesssage}
        message={toastMessage.message}
        isError={toastMessage.isError}
      />}
  </div >;
};