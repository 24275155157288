import React from 'react'
import { Icon } from '../Icon/Icon'

interface MarketDataInterface {
  accordionType: string,
  data?: any;
  handleEdit?: Function
}

export const AccordionBody = ({ accordionType, data, handleEdit }: MarketDataInterface) => {
  if (accordionType === "MarketInfo") {
    return (
      <>
        <div className='w-100 d-flex p-0 mb-2 align-items-center' >
          <p className="w-50 p-0 m-0 text-muted small">Audience type</p>
          <p className='p-0 m-0 text-primary  small' >Custom</p>
        </div>
        <div className='w-100 d-flex p-0 mb-2 align-items-center' >
          <p className="w-50 p-0 m-0 text-muted p-0 small">Incidence rate</p>
          <p className='p-0 m-0 text-primary small ' >{data.incidenceRate}%</p>
        </div>
        <div className='w-100 d-flex p-0 mb-2 align-items-center' >
          <p className="w-50 p-0 m-0 text-muted  p-0 small">Target</p>
          <p className='p-0 m-0 text-primary small ' >{data.targetNumberOfCompletes}</p>
        </div>
        <div className='w-100 d-flex p-0 mb-2 align-items-center' >
          <p className="w-50 p-0 m-0 text-muted p-0 small">Feasibility</p>
          <p className='p-0 m-0 text-primary small ' >To be decided</p>
        </div>
        <div className='w-100 d-flex p-0 mb-2 align-items-center' >
          <p className="w-50 p-0 m-0 text-muted p-0 small">Cost per interview</p>
          <p className='p-0 m-0 text-primary small ' >{data.costPerInterview === "Unavailable" ? "-" : "£ " + Number(data.costPerInterview)}</p>
        </div>
      </>
    )
  } else if (accordionType === "Screening") {
    return (
      <>
        <div className="d-flex justify-content-end align-items-center cursor-pointer p-0 pb-3 small" onClick={() => handleEdit && handleEdit("Cutom Quota", accordionType)}>
          <Icon type="dashboard-edit" className="me-1 fill-primary" /> <span className="mt-1 text-primary small">Edit</span>
        </div>
        {data.screening.map((item, idx) =>
          <div key={idx} className='w-100 d-flex p-0 mb-2' >
            <p className="w-50 p-0 m-0 text-muted small">{item.text}</p>
            {item.answers.length && item.answers.map((answer, idx) =>
              <p key={idx} className='p-0 m-0 text-primary  small' >{answer.text}{item.answers[idx + 1] && ', '}</p>)}
          </div>
        )}
      </>
    )
  } else if (accordionType === "Quotas") {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer p-0 pb-3 " onClick={() => handleEdit && handleEdit("Cutom Quota", accordionType)}>
        <div className="d-flex w-100 justify-content-end align-items cursor-pointer p-0 pb-3 small " onClick={() => handleEdit && handleEdit("Cutom Quota", accordionType)}>
          <Icon type="dashboard-edit" className="me-1 fill-primary" /> <span className="mt-1 text-primary small">Edit</span>
        </div>
        {data.quotas.map((item, idx) =>
          <div key={idx} className='w-100 d-flex p-0 mb-3' >
            <p className="w-50 p-0 m-0 small">{item.text}</p>
            <div className='w-100 d-flex p-0 flex-column'>
              {item.targets.length ? item.targets.map((item, idx) =>
                <div key={idx} className='w-100 d-flex p-0 justify-content-between'>
                  <div>
                    {item.answers.length && item.answers.map((item, idx) =>
                      <span key={idx} className='text-muted text-start fw-light'>{idx ? ', ' : ''}{item.text}</span>)}
                  </div>
                  <span className='fw-light text-end'>{item.targetCompletes}</span>
                </div>) : null}
            </div>
          </div>
        )}
      </div>
    )
  } else if (accordionType === "Interlocked Quotas") {
    return (
      <table className="table w-100 table-summary">
        <caption className='caption-top text-primary bold' >United Kingdom</caption>
        <thead>
          <tr >
            <th className={`bg-ultra-light border`}></th>
            <th colSpan={5} className={`p-2 bold text-center bg-ultra-light`} >Children in family</th>
          </tr>
        </thead>
        <tbody >
          <tr >
            <th rowSpan={5} className={`p-2 bold text-center width:20% bg-ultra-light`} >Gender</th>
          </tr>
          <tr className='text-end'>
            <th colSpan={1} className='border'></th>
            <th className='border p-2 fw-light '>Yes</th>
            <th className='border fw-light '>No</th>
            <th className='border'>Total</th>
          </tr>
          <tr >
            <th colSpan={1} className='border fw-light p-2 ' >Male</th>
            <td className='text-end border'>20%</td>
            <td className='text-end border'>25%</td>
            <td className='text-end border bold'>45%</td>
          </tr>
          <tr >
            <th colSpan={1} className='border fw-light p-2 ' >Female</th>
            <td className='text-end border' >25%</td>
            <td className='text-end border'>50%</td>
            <td className='text-end border bold'>55%</td>
          </tr>
          <tr >
            <th colSpan={1} className='border p-2 '>Total</th>
            <td className='text-end border'>45%</td>
            <td className='text-end border'>55%</td>
            <td className='text-end bold border'>100%</td>
          </tr>
        </tbody>
      </table>
    )
  } else {
    return <></>
  }

}