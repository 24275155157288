export const handleValidation = (dataToCompare: any, errorName: string, typeToCompare: [] | null | string, validationErrors: object, setValidationErrors: Function) => {
  if (typeToCompare === null) {
    if (dataToCompare !== typeToCompare) {
      return setValidationErrors({ ...validationErrors, [errorName]: false })
    } else {
      return setValidationErrors({ ...validationErrors, [errorName]: true })
    }
  } else if (typeToCompare === "number") {
    if (isNaN(dataToCompare) || (parseInt(dataToCompare) <= 0 && parseInt(dataToCompare) > 250)) {
      return setValidationErrors({ ...validationErrors, [errorName]: true })
    } else {
      setValidationErrors({ ...validationErrors, [errorName]: false })
    }
  } else {
    if (dataToCompare.length <= 0) {
      return setValidationErrors({ ...validationErrors, [errorName]: false })
    }
  }
};
