import * as React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Icon } from "../../shared/Icon/Icon";
import { MarketRow } from "../../shared/MarketRow/MarketRow";
import { CountryModal } from "../../shared/CountryModal/CountryModal";
import { surveyTooltips } from "../../../state/surveyTooltips/surveyTooptips";
import { BiddingContextValues } from "../../../contexts/BiddingContext";
import { NavigationContextValues } from "../../../contexts/NavigationContext";
import { handleValidation } from "../../../utils/handleValidation";
import { getCurrentDate } from "../../../utils/getCurrentDate";

interface AdHocProps {
}

export const AdHoc: React.FunctionComponent<AdHocProps> = (props) => {
  const { setCurrentNavSubPage } = React.useContext(NavigationContextValues);
  const { adHocValues, fullService, projectType, surveyInfoValues, errors, setAdHocValues, setFullService, setSurveyInfoValues, setErrors, userEmail } = React.useContext(BiddingContextValues);
  const [marketInputsAccess, setMarketInputsAccess] = React.useState<boolean>(false);
  const [showCountryModal, setShowCountryModal] = React.useState<boolean>(false);

  const findFormErrors = () => {
    const { interviewLength, selectedMarkets, completeAsap } = surveyInfoValues
    const { adHocTargetEndDate } = adHocValues;
    const { programmingComplexity, numberOfQuestions } = fullService;

    let newErrors = {};

    if (interviewLength === "") {
      newErrors = { ...newErrors, interviewLength: true };
    }
    if (selectedMarkets.length <= 0) {
      newErrors = { ...newErrors, selectedMarkets: true }
    }
    if (completeAsap === false) {
      if (adHocTargetEndDate === "") {
        newErrors = { ...newErrors, adHocTargetEndDate: true }
      }
    }
    if (projectType === "FullService") {
      if (programmingComplexity === "") {
        newErrors = { ...newErrors, programmingComplexity: true }
      }
      if (numberOfQuestions === "") {
        newErrors = { ...newErrors, numberOfQuestions: true }
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors })
      return false
    }
    return true
  }

  const closeModal = () => {
    setMarketInputsAccess(false);
    setShowCountryModal(false);
  }

  const showModal = () => {
    setMarketInputsAccess(true)
    setShowCountryModal(true);
  };

  const handleTableRowUpdate = (e: any, marketToUpdate: string, inputToUpdate: string) => {
    if (inputToUpdate === "targetNumberOfCompletes") {
      const newMarketState = surveyInfoValues.selectedMarkets.map(market => market.MarketName === marketToUpdate ? { ...market, TargetNumberOfCompletes: parseInt(e.target.value.slice(0, 5)) } : market)
      setSurveyInfoValues({ ...surveyInfoValues, selectedMarkets: newMarketState })
    } else {
      const newMarketState = surveyInfoValues.selectedMarkets.map(market => market.MarketName === marketToUpdate ? { ...market, IncidenceRate: parseInt(e.target.value.slice(0, 3)) } : market)
      setSurveyInfoValues({ ...surveyInfoValues, selectedMarkets: newMarketState })
    }
  }

  const handleTableRowDelete = (marketNameToRemove: string) => {
    const filteredMarketsData = surveyInfoValues.selectedMarkets.filter((market) => market.MarketName !== marketNameToRemove);
    return setSurveyInfoValues({ ...surveyInfoValues, selectedMarkets: [...filteredMarketsData] });
  }

  const handleCompleteAsap = (value: string | boolean) => {
    if (value === "adHocCompleteAsap") {
      if (adHocValues.adHocTargetEndDate !== "" && surveyInfoValues.completeAsap === false) {
        setSurveyInfoValues({ ...surveyInfoValues, completeAsap: !surveyInfoValues.completeAsap })
        setAdHocValues({ ...adHocValues, adHocTargetEndDate: "" })
      } else {
        setSurveyInfoValues({ ...surveyInfoValues, completeAsap: !surveyInfoValues.completeAsap })
      }
    } else {
      setSurveyInfoValues({ ...surveyInfoValues, completeAsap: false })
      setAdHocValues({ ...adHocValues, adHocTargetEndDate: value })
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid = findFormErrors();
    if (isValid) {
      setCurrentNavSubPage("QuotasTargeting");
    }
  }


  return <div className="container-fluid">
    <div className="row ps-default">
      <div className="col-12 d-flex justify-content-end align-items-center pt-4">
        <p className="p-0 m-0 me-2 text-primary">{userEmail}</p>
        <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
        <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>
      <div className="w-100 max-width-500 py-5 mt-2 pe-2">
        <p className="display-7 text-primary fw-bold mb-4">Add survey ad hoc details</p>
        <div className="mb-4">
          <form className="needs-validation" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className={`table-markets rounded overflow-hidden ${errors.selectedMarkets ? "table-markets" : null}`}>
                <table className="table mb-0 ">
                  <thead>
                    <tr>
                      <td colSpan={2} className="header">Target market</td>
                      <td colSpan={1} className="header fixed">Completes</td>
                      <td colSpan={1} className="header fixed">Incidence rate</td>
                      <td colSpan={1} className="header delete"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {surveyInfoValues.selectedMarkets && surveyInfoValues.selectedMarkets.map((market, index) =>
                      <MarketRow
                        key={`${market} - ${index}`}
                        marketName={market.MarketName}
                        targetNumberOfCompletes={market.TargetNumberOfCompletes}
                        incidenceRate={market.IncidenceRate}
                        marketInputsAccess={marketInputsAccess}
                        handleTableRowUpdate={handleTableRowUpdate}
                        handleTableRowDelete={handleTableRowDelete}
                      />
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5}>
                        <Button className="w-100 mt-1" variant={"secondary"} onClick={showModal}>
                          <Icon type="add-market" fill="text-primary" className="me-1" /> Add market
                        </Button>
                        {showCountryModal &&
                          <CountryModal
                            showCountryModal={showCountryModal}
                            closeModal={closeModal}
                            marketInputsAccess={marketInputsAccess}
                            handleValidation={handleValidation}
                          />
                        }
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {errors && errors.selectedMarkets &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>
              }
            </div>
            <div className="mb-4">
              <label htmlFor="trackerInterviewLength" className="form-label text-primary mb-1 small">Length of interview</label>
              <div className="input-group">
                <input
                  className={`form-control border-right-0 text-primary ${errors.interviewLength ? "is-invalid" : null}`}
                  type="number"
                  id="trackerInterviewLength"
                  placeholder="0"
                  min={0}
                  max={60}
                  value={surveyInfoValues.interviewLength}
                  onChange={(e) => setSurveyInfoValues({ ...surveyInfoValues, interviewLength: parseInt(e.target.value.slice(0, 2)) || "" })}
                  onBlur={() => handleValidation(surveyInfoValues.interviewLength, "interviewLength", null, errors, setErrors)}
                />
                <span className="input-group-text bg-white text-primary border-left"><small className="text-muted">minutes</small></span>
                {errors && errors.interviewLength &&
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                }
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-6">
                <label htmlFor="adHocLanchDate" className="form-label mb-1 text-primary">Launch date</label>
                <input
                  className={`form-control text-primary ${errors.adHocLaunchDate ? "is-invalid" : null}`}
                  type="date"
                  id="adHocLaunchDate"
                  value={adHocValues.adHocLaunchDate}
                  min={getCurrentDate()}
                  onChange={(e) => setAdHocValues({ ...adHocValues, adHocLaunchDate: e.target.value })}
                />
              </div>
              <div className="col-6">
                <label htmlFor="adHocTargetEndDate" className="form-label mb-1 text-primary">End date</label>
                <input
                  className={`form-control text-primary ${errors.adHocTargetEndDate ? "is-invalid" : null}`}
                  type="date"
                  id="adHocTargetEndDate"
                  value={adHocValues.adHocTargetEndDate}
                  min={getCurrentDate()}
                  disabled={surveyInfoValues.completeAsap}
                  onChange={(e) => handleCompleteAsap(e.target.value)}
                  onBlur={() => handleValidation(adHocValues.adHocTargetEndDate, "adHocTargetEndDate", null, errors, setErrors)}
                />
                {errors && errors.adHocTargetEndDate &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
            </div>
            <div className="mb-4">
              <div className="form-check d-flex align-items-center text-primary form-switch mb-0">
                <input
                  className={`form-check-input ${errors.completeAsap ? "is-invalid" : null}`}
                  type="checkbox"
                  role="switch"
                  id="adHocCompleteAsap"
                  checked={surveyInfoValues.completeAsap}
                  onChange={(e) => handleCompleteAsap(e.target.id)}
                />
                <label className="form-check-label" htmlFor="adHocCompleteAsap">Complete as soon as possible</label>
                {errors && errors.completeAsap &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
            </div>
            <div className={`mb-4 ${projectType !== "FullService" ? "d-none" : null}`}>
              <label htmlFor="numberOfQuestions" className="form-label text-primary mb-1 small">Number of questions</label>
              <div className="input-group">
                <input
                  className={`form-control border-right-0 text-primary ${errors.numberOfQuestions ? "is-invalid" : null}`}
                  type="number"
                  id="numberOfQuestions"
                  min={1}
                  max={250}
                  value={fullService.numberOfQuestions}
                  onChange={(e) => setFullService({ ...fullService, numberOfQuestions: parseInt(e.target.value.slice(0, 3)) })}
                  onBlur={() => handleValidation(parseInt(fullService.numberOfQuestions), "numberOfQuestions", "number", errors, setErrors)}
                />
                <span className="input-group-text bg-white text-primary border-left"><small className="text-muted">questions</small></span>
                {errors && errors.numberOfQuestions &&
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                }
              </div>
            </div>
            <div className={`mb-4 ${projectType !== "FullService" ? "d-none" : null}`}>
              <p className={`text-primary mb-1 small ${projectType === "SampleOnly" ? "text-muted" : "text-primary"}`}>
                Scripting complexity{" "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id='surveyTooltip'>{surveyTooltips.programmingComplexity}</Tooltip>}>
                  <span className="small cursor-default bold">(?)</span>
                </OverlayTrigger>
              </p>
              <select
                className={`form-select ${errors.programmingComplexity ? "is-invalid" : null} ${projectType === "SampleOnly" ? "text-muted" : "text-primary"}`}
                id="adHocComplexity"
                aria-label="Scripting complexity"
                onChange={(e) => setFullService({ ...fullService, programmingComplexity: e.target.value })}
                defaultValue={fullService.programmingComplexity}
                disabled={projectType === "SampleOnly"}
                onBlur={() => handleValidation(fullService.programmingComplexity, "programmingComplexity", null, errors, setErrors)}
              >
                <option value={""}>Select complexity</option>
                <option value="Basic">Basic</option>
                <option value="Moderate">Moderate</option>
                <option value="Complex">Complex</option>
                <option value="NotRequired">Not required</option>
              </select>
              {errors && errors.programmingComplexity &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>
              }
            </div>
            <div className="mb-4">
              <div className={`form-check d-flex align-items-center text-primary form-switch mb-2 ${projectType !== "FullService" ? "d-none" : null}`}>
                <input
                  className={`form-check-input ${errors.dataProcess ? "is-invalid" : null}`}
                  type="checkbox"
                  role="switch"
                  id="adHocDataProcess"
                  checked={fullService.dataProcess}
                  onChange={() => setFullService({ ...fullService, dataProcess: !fullService.dataProcess })}
                  disabled={projectType === "SampleOnly"}
                />
                <label className="form-check-label fs-7 lh-1" htmlFor="adHocDataProcess">
                  Select if data processing is required{" "}
                </label>
                {errors && errors.dataProcess &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
              <div className={`form-check d-flex align-items-center text-primary form-switch mb-0 ${projectType !== "FullService" ? "d-none" : null}`}>
                <input
                  className={`form-check-input ${errors.translation ? "is-invalid" : null}`}
                  type="checkbox"
                  role="switch"
                  id="adHocTranslation"
                  checked={fullService.translation}
                  onChange={() => setFullService({ ...fullService, translation: !fullService.translation })}
                  disabled={projectType === "SampleOnly"}
                />
                <label className="form-check-label lh-1" htmlFor="adHocTranslation">
                  Select if translations are required{" "}
                </label>
                {errors && errors.translation &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
            </div>
            <div className="d-grid">
              <button type="submit" value="Submit" className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div >
    </div >
  </div >;
};