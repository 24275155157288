import * as React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd'

import { Icon } from '../Icon/Icon';
import { fetchGetJson } from '../../../services/services';
import { StrictModeDroppable } from './StrictModeDroppable';
import { CustomAudienceModal as CustomAudienceModalInterface } from '../../../interfaces/biddingInterfaces';

interface CustomAudienceModalProps {
  handleModalVisibility: Function;
  setCustomAudienceModalValues: Function;
  selectedMarkets: { MarketName: string, IncidenceRate: string, TargetNumberOfCompletes: string }[]
  interviewLength: string
  userEmail: string
  onSubmitHandler: Function
  customAudienceModalValues: CustomAudienceModalInterface[]
}

interface Answer {
  id: number
  text: string
}

interface MarketData {
  [x: string]: any
  name: string
  answers: Answer[]
}

interface TargetingData {
  answerType: boolean
  category: string
  id: number
  markets: MarketData
  name: string
  text: string
  useAsQuotas: boolean
  isInclude: boolean
  quotaGroups: any[]
  selectedAnswers: { id: number, text: string, quotaPercent: string }[]
}

interface SelectedQuestions {
  questions: Array<TargetingData>;
}

const calculateTotalPrice = (markets) => {
  let totalPrice = 0;
  markets.forEach((market) => {
    totalPrice = totalPrice + parseInt(market.TargetNumberOfCompletes);
  });
  return totalPrice
}
const calculateMarketTotalPrice = (completes, cpi) => {
  let totalPrice = 0;
  totalPrice = parseInt(completes) * parseFloat(cpi)
  return totalPrice
}

export const CustomAudienceModal: React.FunctionComponent<CustomAudienceModalProps> = ({ handleModalVisibility, interviewLength, selectedMarkets, userEmail, setCustomAudienceModalValues, onSubmitHandler, customAudienceModalValues }) => {
  const countries = React.useMemo(() =>
    selectedMarkets.length ? selectedMarkets.map((item: { MarketName: string }) => item.MarketName) : [],
    [selectedMarkets]
  );
  // const markets = ['Global', ...countries]
  const [didMount, setDidMount] = React.useState<boolean>(true);
  const [cpiMount, setCpiMount] = React.useState<boolean>(true);
  const [inputVelueType, setInputValueType] = React.useState<string>("%");
  const [includeExclude, setIncludeExclude] = React.useState<string>("Include");
  const [selectedQuestions, setSelectedQuestions] = React.useState<SelectedQuestions>(Object.assign({}, ...countries.map(market => ({ [market]: [] }))));
  const [targetingData, setTargetingData] = React.useState<TargetingData[]>([]);
  const [tabSelected, setTabSelected] = React.useState<string>(countries[0]);
  const categoriesObj = targetingData.length ? targetingData.reduce((acc, cur) => {
    if (!acc[cur['category']]) { acc[cur['category']] = []; }
    acc[cur['category']].push(cur);
    return acc;
  }, {}) : [];
  const categoriesList = categoriesObj && Object.keys(categoriesObj);
  const [currentDraggableItem, setCurrentDraggableItem] = React.useState<Answer | null>();
  const [interlockedQuotas,
    // setInterlockedQuotas
  ] = React.useState<boolean>(false);
  const [interlockedQuotasNextPage, setInterlockedQuotasNextPage] = React.useState<boolean>(false);
  const [interlockedQuotasValueType, setInterlockedQuotasValueType] = React.useState<string>("%");
  const [interlockedQuotaGroups, setInterlockedQuotaGroups] = React.useState<Array<{ interlockedQuotaOptions: string[] }>>([
    { interlockedQuotaOptions: ["Income", "Children in family", "Gender"] }
  ]);
  const [prices, setPrices] = React.useState<{ [key: string]: string }>({});
  const globalCpi = Object.values(prices).map(Number).reduce((a, b) => a + b, 0) / Object.values(prices).length;
  const globalTargetCompletes = calculateTotalPrice(selectedMarkets)

  React.useEffect(() => {
    if (didMount) {
      fetchGetJson(`/targeting?markets=${countries.toString()}`)
        .then((res: any) => {
          if (res) { setTargetingData(res) }
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          setDidMount(false)
        })
    }
  }, [didMount, countries]);

  React.useEffect(() => {
    if (cpiMount) {
      setCpiMount(false);
      selectedMarkets.forEach(async (selectedMarket: { MarketName: string, IncidenceRate: string, TargetNumberOfCompletes: string }) => {
        const res: any = await fetchGetJson(`/b/cpi?market=${selectedMarket.MarketName}&incidencerate=${selectedMarket.IncidenceRate}&lengthofinterview=${interviewLength}&email=${userEmail}`)
        if (res && res.costPerInterview) {
          setPrices(prevState => ({ ...prevState, [selectedMarket.MarketName]: res.costPerInterview }))
        } else {
          console.log('Error:', res)
        }
      })
    }
  }, [cpiMount, interviewLength, selectedMarkets, userEmail]);

  React.useEffect(() => {
    if (customAudienceModalValues.some(item => item.MarketName)) {
      handleModalVisibility()
      onSubmitHandler()
    }
  }, [customAudienceModalValues, handleModalVisibility, onSubmitHandler]);

  const handleSelectQuestion = (id: number, type: 'add' | 'remove') => {
    if (type === 'remove') {
      let questionToRemove = selectedQuestions[tabSelected].filter(question => question.id !== id)
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToRemove] })
    } else {
      if (!selectedQuestions[tabSelected].find(question => question.id === id)) {
        let questionToAdd = targetingData.filter(question => question.id === id).map((item) => {
          return { ...item, useAsQuotas: false, quotaGroups: [], selectedAnswers: [] }
        })
        setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...selectedQuestions[tabSelected], ...questionToAdd] })
      }
    }
  }

  const handleInputValueType = (inputValueType: string) => {
    inputValueType === "%" ? setInputValueType("#") : setInputValueType("%")
  }

  const handleIncludeExclude = (inputValue: string, id: number) => {
    const newQuestionsState = selectedQuestions[tabSelected].map(question =>
      question.id === id ?
        { ...question, isInclude: inputValue === "Include" ? true : false }
        : question
    );
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
    inputValue === "Include" ? setIncludeExclude("Exclude") : setIncludeExclude("Include")
  }

  const handleSelectAnswer = (e: React.ChangeEvent<HTMLInputElement>, questionId: number, answerObj: Answer) => {
    if (e.target.checked) {
      let addedAnswer = selectedQuestions[tabSelected].map(question =>
        question.id === questionId ?
          { ...question, selectedAnswers: [...question.selectedAnswers, { ...answerObj, quotaPercent: "" }] }
          : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: addedAnswer })
    } else {
      let filteredAnswers = selectedQuestions[tabSelected].map(question =>
        question.id === questionId ?
          { ...question, selectedAnswers: question.selectedAnswers.filter(answer => answer.id !== answerObj.id) }
          : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: filteredAnswers })
    }
  }

  const handleSelectAllAnswers = (questionId: number, tabSelected: string) => {
    let questionToUpdate = selectedQuestions[tabSelected].map(question =>
      question.id === questionId ?
        {
          ...question,
          selectedAnswers: question.markets.find(market => market.name === tabSelected).answers.map(answer => answer),
          quotaGroups: [[]]
        }
        : question
    )
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleQuestionQuotaState = (id: number) => {
    const newQuestionsState = selectedQuestions[tabSelected].map(question =>
      question.id === id ?
        { ...question, useAsQuotas: !question.useAsQuotas, quotaGroups: question.quotaGroups.length ? question.quotaGroups : [[]] }
        : question
    );
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
  }

  const addQuotaGroup = (questinId: number) => {
    let newArr = [];
    let arrayToUpdate = selectedQuestions[tabSelected]
    arrayToUpdate.map((question) => {
      return question.id === questinId && question.quotaGroups.push(newArr);
    })
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...arrayToUpdate] })
  }

  const removeQuotaGroup = (questionId: number, index: number) => {
    let arrayToUpdate = selectedQuestions[tabSelected];
    arrayToUpdate.map(question => {
      return question.id === questionId && question.quotaGroups.splice(index, 1);
    })
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...arrayToUpdate] })
  }

  const onDragEnd = (result) => {
    const { destination } = result;
    if (destination && currentDraggableItem !== null) {
      let ids = destination.droppableId.split("-", 3);
      let newQuestionState = selectedQuestions[tabSelected];

      setSelectedQuestions({
        ...selectedQuestions, [tabSelected]:
          newQuestionState.map((question) => {
            if (question.id === parseInt(ids[1])) {
              question.quotaGroups[parseInt(ids[2])].push(currentDraggableItem)
              let newSelectedAnswers = question.selectedAnswers.filter(answer => answer.id !== currentDraggableItem?.id);
              return { ...question, selectedAnswers: newSelectedAnswers }
            } else {
              return question
            }
          })
      })
      setCurrentDraggableItem(null);
    };
    return;
  }

  const handleQuotaGroupAnswerDelete = (questionId: number, answerId: number, groupIndex: number) => {
    let questionToUpdate = selectedQuestions[tabSelected]
    questionToUpdate.map(question =>
      question.id === questionId ?
        question.quotaGroups[groupIndex] = question.quotaGroups[groupIndex].filter(answer => answer.id !== answerId)
        : question
    )
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleQuotaRoundNumber = (value: any, questionId: number, answerId: number, isQuotaGroup: boolean) => {
    let questionToUpdate = selectedQuestions[tabSelected]
    if (!isQuotaGroup) {
      questionToUpdate.map(question =>
        question.id === questionId ?
          { ...question, selectedAnswers: question.selectedAnswers.map(answer => answer.id === answerId ? answer.quotaPercent = value : answer) }
          : question
      )
    } else {
      questionToUpdate.map(question =>
        question.id === questionId ?
          { ...question, quotaGroups: question.quotaGroups.map((quotaGroup, idx) => idx === answerId ? quotaGroup.map(item => item.quotaPercent = value) : quotaGroup) }
          : question
      )
    }
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleInterlockedQuotas = (direction: string) => {
    if (interlockedQuotasNextPage === false && interlockedQuotas && direction === "Next") {
      setInterlockedQuotasNextPage(true);
    } else if (interlockedQuotasNextPage && direction === "Back") {
      setInterlockedQuotasNextPage(false);
    }
  }

  const handleInterlockedQuotasValueType = (inputValueType: string) => {
    inputValueType === "%" ? setInterlockedQuotasValueType("#") : setInterlockedQuotasValueType("%");
  }

  const addInterlockedQuotaGroup = () => {
    setInterlockedQuotaGroups([...interlockedQuotaGroups, { interlockedQuotaOptions: ["Income", "Children in family", "Gender"] }]);
  }

  const removeInterlockedQuotaGroup = (index: number) => {
    const newInterlockedQuotaGroups = interlockedQuotaGroups.splice(index, 1);
    setInterlockedQuotaGroups(newInterlockedQuotaGroups);
  }

  const handleContinueButton = (e) => {
    const customAudienceModalData = countries.map(market => {
      const screeningData = selectedQuestions[market].map((question, idx) => !question.useAsQuotas ?
        {
          "questionId": question.id,
          "name": question.name,
          "Text": question.text,
          "isInclude": question.isInclude ? true : false,
          "answers": question.selectedAnswers.map(({ quotaPercent, ...rest }) => rest)
        } : [])
      const quotasData = selectedQuestions[market].map((question, idx) => {
        const quotaGroupData =
          question.quotaGroups.flat().length && question.useAsQuotas ? question.quotaGroups.map((item, idx) => ({
            "targetCompletes": inputVelueType === "%" ? Math.ceil((Number(item[0].quotaPercent) / 100) * Number(selectedMarkets[0].TargetNumberOfCompletes)) : Number(item[0].quotaPercent),
            "answers": item.map(({ quotaPercent, ...rest }) => rest)
          })) : []
        const selectedAnswersData =
          question.selectedAnswers.length && question.useAsQuotas ? question.selectedAnswers.map((item, idx) => ({
            "targetCompletes": inputVelueType === "%" ? Math.ceil((Number(item.quotaPercent) / 100) * Number(selectedMarkets[0].TargetNumberOfCompletes)) : Number(item.quotaPercent),
            "answers": [{
              "id": item.id,
              "text": item.text
            }]
          })) : []
        const quotasData = [...quotaGroupData, ...selectedAnswersData]
        return (quotasData.length ? {
          "questionId": question.id,
          "name": question.name,
          "Text": question.text,
          "targets": quotasData
        } : [])
      })
      return ({
        MarketName: market,
        TargetNumberOfCompletes: selectedMarkets.find(selectedMarket => selectedMarket.MarketName === market)?.TargetNumberOfCompletes,
        IncidenceRate: selectedMarkets.find(selectedMarket => selectedMarket.MarketName === market)?.IncidenceRate,
        screening: screeningData.flat(),
        quotas: quotasData.flat()
      })
    })
    setCustomAudienceModalValues(customAudienceModalData)
  }

  return <div className="cs-audience-wrapper">
    <div className="cs-audience-modal overflow-hidden">
      <div className="cs-audience-title-wrapper p-3 border-bottom d-flex justify-content-between align-items-center">
        <h6 className="text-primary m-0">Custom audience</h6>
        <div onClick={() => handleModalVisibility()}><Icon type="close" fill="#19394C" className="cursor-pointer"></Icon></div>
      </div>
      <div className="cs-audience-body position-relative ">
        {interlockedQuotasNextPage ?
          <div className=" w-100 bg-light">
            <div className="bg-white border-bottom d-flex w-100 px-3 pt-1">
              <div role="button" className="fs-7 p-2 me-2 text-primary border-bottom border-primary border-2">
                Global
              </div>
              <div role="button" className="text-muted fs-7 p-2 me-2">
                United Kingdom
              </div>
              <div role="button" className="text-muted fs-7 p-2 me-2">
                Sweden
              </div>
            </div>
            <div className="p-3">
              {interlockedQuotaGroups.map((group, index) => {
                return <div className="accordion mb-3" id={`accodrionExample${index}`} key={index}>
                  <div className="accordion-item">
                    <div className="accordion-header position-relative py-3" id="headingTwo">
                      <button className="accordion-button collapsed py-2 d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={`#main${index}`} aria-expanded="false" aria-controls={`main#${index}`}>
                        <span className="d-flex flex-column w-100-200">
                          <span className="text-primary mb-1">Interlocked quotas #{index + 1}</span>
                        </span>
                      </button>
                      <span className="d-flex align-items-center position-absolute top-50 end-0 translate-middle-y me-5 z-index-2000">
                        <div className="form-check d-flex align-items-center text-primary form-switch me-2">
                          <div className="cs-input-switch" onClick={() => handleInterlockedQuotasValueType(interlockedQuotasValueType)}>
                            <button className={`btn border-0 cs-input-item bold ${interlockedQuotasValueType === "%" ? "active" : ""}`}>%</button>
                            <button className={`btn border-0 cs-input-item bold ${interlockedQuotasValueType === "#" ? "active" : ""}`}>#</button>
                          </div>
                        </div>|
                        <div onClick={() => removeInterlockedQuotaGroup(index)}>
                          <Icon type="delete" fill="" className="ms-1 me-2 cursor-pointer" />
                        </div>
                      </span>
                    </div>
                    <div id={`main${index}`} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={`accodrionExample${index}`}>
                      <div className="accordion-body">
                        <div className="border rounded-2 p-3 mb-4 d-flex">
                          <div className="bg-walr rounded-2 py-2 px-3 me-2">
                            <div className="form-check">
                              <input className="form-check-input box-shadow-none" type="checkbox" value="" id="flexCheckDefault1" />
                              <label className="form-check-label fs-7" htmlFor="flexCheckDefault1">
                                Default checkbox
                              </label>
                            </div>
                          </div>
                          <div className="bg-light rounded-2 py-2 px-3">
                            <div className="form-check">
                              <input className="form-check-input box-shadow-none" type="checkbox" value="" id="flexCheckDefault2" />
                              <label className="form-check-label fs-7" htmlFor="flexCheckDefault2">
                                Default checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="d-flex align-items-center justify-content-center px-5 bg-light">
                            <p className="m-0">Gender</p>
                          </div>
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th colSpan={4} className="border-end border-start border-light text-center bg-light">Children in family</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border-bottom border-end w-25 border-start"></td>
                                <td className="border-bottom border-end w-25 text-end">Yes</td>
                                <td className="border-bottom border-end w-25 text-end">No</td>
                                <td className="border-bottom border-end w-25 text-end">Total</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-end w-25 border-start">Male</td>
                                <td className="border-bottom border-end w-25 text-end">25%</td>
                                <td className="border-bottom border-end w-25 text-end">30%</td>
                                <td className="border-bottom border-end w-25 text-end">100%</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-end w-25 border-start">Female</td>
                                <td className="border-bottom border-end w-25 text-end">25%</td>
                                <td className="border-bottom border-end w-25 text-end">30%</td>
                                <td className="border-bottom border-end w-25 text-end">100%</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-end w-25 border-start">Total</td>
                                <td className="border-bottom border-end w-25 text-end">25%</td>
                                <td className="border-bottom border-end w-25 text-end">30%</td>
                                <td className="border-bottom border-end w-25 text-end">100%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <button
                className="w-100 d-flex align-items-center justify-content-center py-2 rounded-2 border-1 border-dashed bg-transparent"
                onClick={addInterlockedQuotaGroup}>
                <Icon type="add-market" className="me-1" /> Add market
              </button>
            </div>
          </div> :
          <>
            <div className="cs-audience-sidebar border-end overflow-hidden">
              <div className="cs-audience-input-group p-3">
                <span className="cs-audience-input-text"><Icon type="search" fill="#17214780" /></span>
                <input type="search" className="cs-audience-search text-primary" placeholder="Search target variables" />
              </div>
              <div className="cs-side-accordion-wrapper p-3 pt-0 overflow-auto">
                <div className="accordion h-100" id="accordionExample">
                  {categoriesList && categoriesList.map((categoryName: string, idx: number) =>
                    <div key={idx} className="accordion-item">
                      <h2 className="accordion-header" id={`heading${idx}`}>
                        <button
                          className="accordion-button collapsed py-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${idx}`}
                          aria-expanded="false"
                          aria-controls={`collapse${idx}`}>
                          <Icon type="audience-folder" className="me-1" /> <h6 className="p-0 m-0">{categoryName}</h6>
                        </button>
                      </h2>
                      <div
                        id={`collapse${idx}`}
                        className="accordion-collapse collapse mt-1"
                        aria-labelledby={`heading${idx}`}
                        data-bs-parent={`#collapse${idx}`}>
                        {categoriesObj[categoryName].map((data: TargetingData, index: number) =>
                          <div
                            key={index}
                            className={`accordion-body accordion-question mb-2 rounded d-flex align-items-center cursor-pointer ${selectedQuestions[tabSelected].find(question => question.id === data.id) && 'disabled'}`}
                            onClick={() => handleSelectQuestion(data.id, 'add')}>
                            {/* <Icon type="draggable" fill={`${selectedQuestions[tabSelected].find(question => question.id === data.id) && '#19394cb3'}`} className={`me-2 flex-shrink-0`} /> */}
                            <span style={{ fontSize: '13px' }}>{data.text}</span>
                          </div>)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="cs-audience-main bg-white">
              <ul className="nav nav-pills targeting-tabs flex-grow-1 ps-3 border-bottom">
                {/* <div onClick={() => setTabSelected('Global')} role="button" className={`fs-7 p-2 me-2 ${tabSelected === 'Global' ? 'text-primary border-bottom border-primary border-2' : 'text-muted'}`}>
                  Global
                </div> */}
                {countries.map((country: string, idx: number) =>
                  <li
                    className={`nav-item ${tabSelected === country ? 'active' : ''}`}
                    onClick={() => setTabSelected(country)}
                    key={idx} role="button">
                    {country}
                  </li>
                )}
              </ul>
              <div className={`cs-main-questions-wrapper p-3 ${didMount ? "d-flex justify-content-center align-items-center" : ""}`}>
                {didMount ? <div className="spinner-border" role="status" /> : null}
                {selectedQuestions[tabSelected].length > 0 ? selectedQuestions[tabSelected].map((question: TargetingData, idx: number) => {
                  return <div key={idx} className="accordion mb-2" id="accordionExample">
                    <div className="accordion-item">
                      <div className="accordion-header position-relative" id={`heading${idx}`}>
                        <button className="accordion-button collapsed py-2 d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={`#main${idx}`} aria-expanded="false" aria-controls="main">
                          <span className="d-flex flex-column w-100-200">
                            <span className="small text-indigo-dark bold mb-1">{question.name}</span>
                            <span className="text-primary bold fs-7">{question.text}</span>

                          </span>
                        </button>
                        <span className="d-flex align-items-center position-absolute top-50 end-0 translate-middle-y me-5 z-index-2000">
                          <div className="form-check d-flex align-items-center text-primary form-switch me-2">
                            <label className="form-check-label fs-7 bold">
                              <input
                                className="form-check-input cursor-pointer box-shadow-none"
                                type="checkbox"
                                role="switch"
                                id="useAsQuotas"
                                checked={question.useAsQuotas}
                                onChange={() => handleQuestionQuotaState(question.id)}
                              />
                              Use as quotas</label>
                          </div>
                          <div className='divider'></div>
                          <span onClick={() => handleSelectQuestion(question.id, 'remove')}>
                            <Icon type="delete" fill="" className="ms-1 me-2 cursor-pointer" />
                          </span>
                        </span>
                      </div>
                      <div id={`main${idx}`} className="accordion-collapse collapse" aria-labelledby={`heading${idx}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body pt-0">
                          <div className="d-flex justify-content-between w-100 pb-3">
                            <div className="d-flex">
                              {!question.useAsQuotas && <div className="cs-input-switch me-3" onClick={() => handleIncludeExclude(includeExclude, question.id)}>
                                <button className={`btn border-0 cs-input-item bold ${question.isInclude ? "active" : ""}`}>Include</button>
                                <button className={`btn border-0 cs-input-item bold ${!question.isInclude ? "active" : ""}`}>Exclude</button>
                              </div>}
                              <button className="btn border text-primary" onClick={() => handleSelectAllAnswers(question.id, tabSelected)}>Select all</button>
                            </div>
                            {question.useAsQuotas && <div className="cs-input-switch" onClick={() => handleInputValueType(inputVelueType)}>
                              <button className={`btn border-0 cs-input-item bold ${inputVelueType === "%" ? "active" : ""}`}>%</button>
                              <button className={`btn border-0 cs-input-item bold ${inputVelueType === "#" ? "active" : ""}`}>#</button>
                            </div>}
                          </div>
                          {question.useAsQuotas ?
                            <DragDropContext onDragEnd={onDragEnd}>
                              <div className='d-flex text-muted bold medium mb-2'>Answer text</div>
                              <StrictModeDroppable droppableId={"dropable-1"}>
                                {(provided) => {
                                  return <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {question.markets.find((market: MarketData) => market.name === tabSelected)?.answers.map((answer: Answer, index: number) => {
                                      return <Draggable draggableId={answer.id.toString()} index={index} key={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`d-flex justify-content-between px-2 py-1 answer-row rounded cursor-pointer text-truncate
                                            ${snapshot.isDragging ? 'dragging' : ''}
                                            ${question.useAsQuotas && question.quotaGroups.find(group => group.length > 0 && group.includes(answer)) ? 'disabled' : ''}`}>
                                            <div className="d-flex align-items-center " onMouseDown={() => !question.quotaGroups.find(group => group.length > 0 && group.includes(answer)) && setCurrentDraggableItem(answer)}>
                                              {question.useAsQuotas && <Icon type="draggable" className="me-1 cursor-pointer handle" />}
                                              <div className="form-check d-flex align-items-center m-0">
                                                <label className="form-check-label ms-1 fs-7">
                                                  <input
                                                    className="form-check-input mb-0 me-2 box-shadow-none"
                                                    type="checkbox"
                                                    checked={Object.values(question.selectedAnswers.map(ans => ans.id)).includes(answer.id)}
                                                    disabled={question.quotaGroups.find(group => group.length > 0 && group.includes(answer))}
                                                    onChange={(e) => { handleSelectAnswer(e, question.id, answer) }} />
                                                  <span className='answer-text'>{answer.text}</span>
                                                </label>
                                              </div>
                                            </div>
                                            {question.useAsQuotas && <div className="input-group max-width-85 h-100 align-items-center">
                                              <input
                                                type="number"
                                                step={5}
                                                min={0}
                                                max={100}
                                                value={question.selectedAnswers.find(item => item.text === answer.text)?.quotaPercent ?
                                                  question.selectedAnswers.find(item => item.text === answer.text)?.quotaPercent
                                                  : ""
                                                }
                                                disabled={question.selectedAnswers.find(answerToFind => answerToFind.id === answer.id)?.id !== answer.id}
                                                className="form-control shadow-none fs-7 ps-2 pt-2 pb-2 pe-1 h-75"
                                                onChange={(e) => handleQuotaRoundNumber(e.target.value.slice(0, 3), question.id, answer.id, false)}
                                              />
                                              <span
                                                className="input-group-text ps-2 pe-2 h-75 fs-7 bg-white"
                                                id="basic-addon1">
                                                {inputVelueType}
                                              </span>
                                            </div>}
                                          </div>
                                        )}
                                      </Draggable>
                                    })}
                                    {provided.placeholder}
                                  </div>
                                }}
                              </StrictModeDroppable>
                              <div className={`cs-new-group-wrapper ${question.useAsQuotas ? "h-auto mt-3" : "h-0"}`}>
                                <button
                                  className="btn border-0 p-0 text-indigo-dark mb-2"
                                  onClick={() => addQuotaGroup(question.id)}>
                                  Add new group
                                </button>
                                {question.quotaGroups.length > 0 && question.quotaGroups.map((group, index) => {
                                  return <StrictModeDroppable droppableId={`questionGroup-${question.id}-${index}`} key={index} >
                                    {(provided) => {
                                      return <div className="card mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                                        <div className="card-header d-flex align-items-center justify-content-between bg-white">
                                          <div className='bold text-primary'>Group {index + 1}</div>
                                          <div className="d-flex">
                                            <div className="input-group max-width-90">
                                              <input onChange={(e) => handleQuotaRoundNumber(e.target.value.slice(0, 3), question.id, index, true)} type="number" className="form-control form-control-sm shadow-none" placeholder="" />
                                              <span className="input-group-text ps-2 pe-2 h-100 fs-7 bg-white" id="basic-addon1">{inputVelueType}</span>
                                            </div>
                                            <div className="btn-group ms-2">
                                              <button type="button" className="btn p-1 border bg-white rounded" onClick={() => removeQuotaGroup(question.id, index)}><Icon type="delete" fill="#172147" /></button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-body d-flex flex-wrap">
                                          {group.length <= 0 ?
                                            <div className="cs-dashed-box">
                                              <p className="text-muted m-0">Drag question answers here to add to group</p>
                                            </div>
                                            :
                                            group.map((answer) =>
                                              <div
                                                className="btn py-1 px-2 bg-walr cursor-default text-primary me-2 mb-2 d-flex align-items-center justify-content-lg-center"
                                                key={answer.id}>
                                                <span className="text-start">{answer.text}</span>
                                                <span
                                                  className="ms-1 text-primary cursor-pointer"
                                                  onClick={() => handleQuotaGroupAnswerDelete(question.id, answer.id, index)}>
                                                  <Icon type="close" /></span>
                                              </div>
                                            )
                                          }
                                        </div>
                                        {provided.placeholder}
                                      </div>
                                    }}
                                  </StrictModeDroppable>
                                })}
                              </div>
                            </DragDropContext> :
                            <>
                              {question.markets.find((market: MarketData) => market.name === tabSelected)?.answers.map((answer: Answer, index: number) => {
                                return <div className="d-flex justify-content-between p-2 answer-row rounded cursor-pointer" key={answer.id}>
                                  <div className="d-flex align-items-center">
                                    {question.useAsQuotas && <Icon type="draggable" className="me-2 cursor-pointer" />}
                                    <div className="form-check d-flex align-items-center m-0">
                                      <label className="d-flex align-items-center form-check-label ms-1 fs-7">
                                        <input
                                          className="form-check-input mb-0 me-2 box-shadow-none"
                                          type="checkbox"
                                          checked={Object.values(question.selectedAnswers.map(ans => ans.id)).includes(answer.id)}
                                          onChange={(e) => { handleSelectAnswer(e, question.id, answer) }
                                          } />
                                        {answer.text}
                                      </label>
                                    </div>
                                  </div>
                                  {question.useAsQuotas && <div className="input-group h-100 max-width-85 align-items-center">
                                    <input type="number" className="form-control shadow-none fs-7 ps-2 pt-2 pb-2 pe-1 h-75" placeholder="" />
                                    <span className="input-group-text ps-2 pe-2 h-75 fs-7" id="basic-addon1">{inputVelueType}</span>
                                  </div>}
                                </div>
                              })}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }) : !didMount ? <div className='empty text-muted d-flex align-items-center justify-content-center p-4'>Drag and drop target variables here</div> : null}
              </div>
            </div>
            <div className="cs-audience-sidebar border-start py-3 px-0 d-flex flex-column justify-content-between overflow-auto">
              <div>
                <div className='border-bottom mb-3 px-3'>

                  <p className="text-primry fs-4 lh-1 mb-2 mt-2">
                    {tabSelected === 'Global' ? `£${globalTargetCompletes * globalCpi}` :
                      `£${calculateMarketTotalPrice(selectedMarkets.find((market) => market.MarketName === tabSelected)?.TargetNumberOfCompletes, prices[tabSelected])}`
                    }
                  </p>
                  <p className="text-muted fs-8 mb-0">Total estimated cost</p>
                  <p className="text-muted fs-8 mb-3">Based on {tabSelected === 'Global' ? globalTargetCompletes : selectedMarkets.find((market) => market.MarketName === tabSelected)?.TargetNumberOfCompletes} completes x £{tabSelected === 'Global' ? globalCpi : prices[tabSelected]} each</p>
                </div>
                {/* <div>
                  <p className="text-primary fs-7 d-flex mb-2 justify-content-between"><span>Number of respondents</span> <span>24</span></p>
                  <div className="progress mb-2" style={{ height: "6px" }}>
                    <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "25%" }} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}></div>
                  </div>
                  <p className="text-muted fs-8 mb-0">Max achievable respondents: 325</p>
                </div> */}
                <div className='border-bottom mb-3 px-3'>
                  <p className="fs-7 mb-3 bold text-primary">Screening</p>
                  <div>
                    {selectedQuestions[tabSelected].map((question, idx) => {
                      if (!question.useAsQuotas && question.selectedAnswers.length > 0) {
                        return <div key={idx}>
                          <p className="text-primary fw-semibold fs-7 mb-1">{question.text}</p>
                          <p className="text-primary fs-8">
                            {question.selectedAnswers.map((answer, index) =>
                              <span key={index}>{answer.text}{question.selectedAnswers[index + 1] && ', '}</span>
                            )}
                          </p>
                        </div>
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
                <div className='mb-3 px-3'>
                  <p className="fs-7 mb-3 bold text-primary">Quotas</p>
                  <div>
                    {selectedQuestions[tabSelected].map((question, idx) => {
                      if (question.useAsQuotas && (question.quotaGroups.length > 0 || question.selectedAnswers.length > 0)) {
                        return <div key={idx}>
                          <p className="text-primary fw-semibold fs-7 mb-1">{question.text}</p>
                          {question.selectedAnswers.length > 0 && question.selectedAnswers.map((answer, index) =>
                            <p key={index} className="text-primary fs-8 mb-1 d-flex justify-content-between">
                              <span className='text-truncate w-85'>{answer.text}</span>
                              {answer.quotaPercent && <span>{answer.quotaPercent}{inputVelueType}</span>}
                            </p>)}
                          {question.quotaGroups.length > 0 && question.quotaGroups.map((quotaGroup, index) =>
                            <p key={index} className="text-primary fs-8 d-flex justify-content-between">
                              <span className='d-flex w-75'>
                                {quotaGroup.map((answer, idx) =>
                                  idx < 1 && <span className='text-truncate me-1' key={idx}>{answer.text}</span>)}
                                {quotaGroup.length > 1 &&
                                  <span
                                    className='cs-audience-tooltip'>
                                    +{quotaGroup.length - 1}
                                    <span
                                      className='cs-audience-tooltip-message'>
                                      {quotaGroup.map((answer, idx) => idx > 0 ? <span key={idx}>{answer.text}{quotaGroup[idx + 1] && ', '}</span> : null)}
                                    </span>
                                  </span>}
                              </span>
                              {quotaGroup[index] && quotaGroup[index].quotaPercent && <span>{quotaGroup[index].quotaPercent}{inputVelueType}</span>}
                            </p>)}
                        </div>
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
              {/* <div className='px-3'>
                <div className="form-check d-flex align-items-center text-primary form-switch me-2">
                  <input
                    className="form-check-input cursor-pointer box-shadow-none"
                    type="checkbox"
                    role="switch"
                    id="interlockedQuotas"
                    checked={interlockedQuotas}
                    onChange={() => setInterlockedQuotas(!interlockedQuotas)}
                  />
                  <label className="form-check-label fs-7" htmlFor="interlockedQuotas">Interlocked quotas</label>
                </div>
              </div> */}
            </div>
          </>
        }
      </div>
      <div className="cs-audience-footer p-3 border-top">
        {interlockedQuotasNextPage &&
          <button type="button" onClick={() => handleInterlockedQuotas("Back")} className="btn btn-transparent border me-2 ">
            Back
          </button>
        }
        <button type="button" className="btn btn-primary" onClick={(e) => interlockedQuotas ? handleInterlockedQuotas("Next") : handleContinueButton(e)}>Continue</button>
      </div>
    </div >
  </div >;
};