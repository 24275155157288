import React, { createContext, useMemo, useState } from 'react'

interface ToastMessage {
  isError?: boolean,
  message: string | undefined,
}

type SetToastMessage = {
  toastMessage: ToastMessage,
  setToastMessage: React.Dispatch<React.SetStateAction<ToastMessage>>,

}

export const ToastContext = createContext({} as SetToastMessage)

export const ToastMessageProvider = ({ children }) => {

  const [toastMessage, setToastMessage] = useState<ToastMessage>({
    isError: false,
    message: "",
  })

  const memoMessage = useMemo(() => ({ toastMessage, setToastMessage }), [toastMessage])

  return (
    <ToastContext.Provider value={memoMessage} >
      {children}
    </ToastContext.Provider>
  )
}