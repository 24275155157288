import React, { createContext, useState, useEffect } from 'react';

import { GetStarted } from '../components/GetStarted/GetStarted';
import { ProjectDetails } from '../components/ProjectDetails/ProjectDetails';
import { BidReference } from '../components/Review/BidReference/BidReference'
import { Review } from '../components/Review/Review';

interface NavigationContextInterface {
  currentActivePage: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  currentNavPage: string;
  setCurrentNavPage: Function;
  currentNavSubPage: string;
  setCurrentNavSubPage: Function;
}

const NavigationContext = createContext({} as NavigationContextInterface);


export const Provider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  const [currentNavPage, setCurrentNavPage] = useState<string>("GetStarted");
  const [currentNavSubPage, setCurrentNavSubPage] = useState<string>("")
  const [currentActivePage, setCurrentActivePage] = useState(<GetStarted />);

  useEffect(() => {
    if (currentNavPage === "ProjectDetails") {
      setCurrentActivePage(<ProjectDetails />);
    } else if (currentNavPage === "Review") {
      setCurrentActivePage(<Review />);
    } else if (currentNavPage === "BidReference") {
      setCurrentActivePage(<BidReference />)
    } else {
      setCurrentActivePage(<GetStarted />)
    }
  }, [currentNavPage]);

  const navigationValues = {
    currentActivePage,
    setCurrentActivePage,
    currentNavPage,
    setCurrentNavPage,
    currentNavSubPage,
    setCurrentNavSubPage,
  }
  return (
    <NavigationContext.Provider value={navigationValues}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export const NavigationContextProvider = Provider;
export const NavigationContextValues = NavigationContext;