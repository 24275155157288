import * as React from "react";
import { Spinner } from "react-bootstrap";

import { VerifyUser } from "./VerifyUser/VerifyUser";
import { BiddingContextValues } from "../../contexts/BiddingContext";
import { NavigationContextValues } from "../../contexts/NavigationContext";
import { handleValidation } from "../../utils/handleValidation";
import { fetchPost } from "../../services/services";

interface GetStartedProps {
}

export const GetStarted: React.FunctionComponent<GetStartedProps> = (props) => {
  const { setCurrentNavPage, setCurrentNavSubPage, currentNavSubPage } = React.useContext(NavigationContextValues)
  const { userEmail, setUserEmail, errors, setErrors, setUserAuth, bidLoading, setBidLoading } = React.useContext(BiddingContextValues)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setBidLoading(true)
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)) {
      setErrors({ ...errors, userEmail: false });
      fetchPost(`/b/verify?email=${userEmail}`)
        .then((res: { verified: boolean; }) => {
          if (res.verified) {
            setBidLoading(false);
            setCurrentNavPage("ProjectDetails");
            setCurrentNavSubPage("SurveyInformation");
          } else {
            setBidLoading(false);
            setUserAuth(true);
            setCurrentNavSubPage("VerifyUser");
          }
        })
        .catch((err: any) => console.log(err))
    } else {
      setErrors({ ...errors, userEmail: true })
    }
  }

  return <div className="container-fluid">
    <div className="row ps-default">
      <div className="d-flex justify-content-end pt-4">
        <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
      </div>
      {currentNavSubPage === "VerifyUser" ? <VerifyUser /> :
        <div className="w-100 py-5 mt-5 ps-5 pe-2">
          <div className="walr-graphic"></div>
          <div className="walr-illustration"></div>
          <p className="walr-bids-badge bold">
            Walr Bids
          </p>
          <div className="d-flex flex-column mb-4 walr-bids-pills">
            <p className="light">
              The easiest way
            </p>
            <p className="light indent-2">
              to field all of your
            </p>
            <p className="dark indent-3">
              online surveys.
            </p>
          </div>
          <div className="w-100 max-width-500">
            <p className="text-muted fs-6 mb-4">
              Providing a simpler way for you to field all of your online surveys, with the peace of mind that your audience will be delivered exactly as required.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label text-primary mb-1 small bold">Email address</label>
                <input
                  type="email"
                  className={`form-control ${errors.userEmail ? "is-invalid" : null}`}
                  id="exampleInputEmail1"
                  placeholder="you@example.com"
                  onChange={(e) => setUserEmail(e.target.value)}
                  onBlur={() => handleValidation(userEmail, "userEmail", null, errors, setErrors)}
                />
                {errors && errors.userEmail &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
              <button type="submit" value="Submit" className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
                {bidLoading ? <><Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                  <span>Verifying...</span>
                </> : "Continue"}
              </button>
              <p className="legal text-muted mt-3">By continuing, you agree to Walr's <a href="https://walr.com/app/uploads/2022/12/Walr-Terms-and-Conditions.pdf" target="_blank" rel="noreferrer">Terms & Conditions</a> and acknowledge you've read our <a href="https://walr.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>, more details can be found <a href="https://walr.com/legal/" target="_blank" rel="noreferrer">here</a>.</p>
            </form>
          </div>
        </div>
      }
    </div>
  </div>;
};