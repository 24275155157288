import React from 'react'

import { NavigationContextValues } from '../../../contexts/NavigationContext';

export const ToastMessage = ({ message, isError, show, setShow }) => {
  const { setCurrentNavPage, setCurrentNavSubPage } = React.useContext(NavigationContextValues)

  React.useEffect(() => {
    if (!isError) {
      setTimeout(() => {
        setShow("")
      }, 7000)
    }

  }, [isError, message, show, setShow])

  const handleRedirect = () => {
    setCurrentNavPage("ProjectDetails");
    setCurrentNavSubPage("SurveyInformation")
  }

  return (
    <div className={`toast-container position-fixed p-3 mb-2  bottom-0 end-0
    `} id="toastPlacement" >
      <div className={`toast ${show} bg-primary `} >
        <div className={`toast-header bg-primary`} >
          <p className={`me-auto text-white m-0`}>{message}</p>
          {isError &&
            <>
              <small className=" text-white text-decoration-underline" role="button" onClick={handleRedirect}>try again </small>
              <button type="button" className="ml-2 mb-1 close bg-transparent border-0 text-white" onClick={() => setShow("")}>
                <span aria-hidden="true">&times;</span>
              </button>
            </>
          }
        </div>
      </div>
    </div>
  )
}
