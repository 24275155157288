import * as React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from "react-bootstrap";

import { BiddingContextValues } from '../../../contexts/BiddingContext';
import { fetchGetJson } from '../../../services/services';
import { MarketModalRow } from '../MarketModalRow/MarketModalRow';

interface CountryModalProps {
  showCountryModal: boolean;
  closeModal: Function;
  marketInputsAccess: boolean;
  handleValidation: Function;
}

export const CountryModal: React.FunctionComponent<CountryModalProps> = ({ showCountryModal, closeModal, marketInputsAccess, handleValidation }) => {
  const { surveyInfoValues, setSurveyInfoValues, errors, setErrors } = React.useContext(BiddingContextValues);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [countriesData, setCountiresData] = React.useState<[] | undefined>();
  const [selectCountry, setSelectCountry] = React.useState<string>();
  const [selectedModalMarkets, setSelectedModalMarkets] = React.useState<Array<{ MarketName: string, TargetNumberOfCompletes: string, IncidenceRate: string }>>([]);
  const [countryIsSelected, setCountryIsSelected] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsLoading(true)
    fetchGetJson("/b/supportedmarkets")
      .then((res: any) => {
        if (res) { setCountiresData(res.markets) }
      })
      .catch((error) => { console.log(error); })
      .finally(() => { setIsLoading(false) })
  }, []);

  const handleCloseModal = () => {
    setSelectedModalMarkets([])
    closeModal()
  }

  const handleCountrySelect = (val: string) => {
    const findResults = selectedModalMarkets.find(obj => obj.MarketName === val);
    if (findResults?.MarketName === val) {
      setSelectCountry("")
    } else {
      setSelectCountry(val)
      setSelectedModalMarkets([...selectedModalMarkets, { MarketName: val, TargetNumberOfCompletes: "0", IncidenceRate: "0" }])
      setSelectCountry("")
    }
  }

  const handleTableRowDelete = (marketNameToRemove: string) => {
    const filteredMarketsData = selectedModalMarkets.filter((market) => market.MarketName !== marketNameToRemove);
    setCountryIsSelected(false)
    return setSelectedModalMarkets(filteredMarketsData);
  }

  const handleSubmit = () => {
    if (surveyInfoValues.selectedMarkets.length > 0) {
      const filterPreviouslyAddedMarkets = selectedModalMarkets.filter((market, index) => market.MarketName !== surveyInfoValues.selectedMarkets[index]?.MarketName);
      setSurveyInfoValues({ ...surveyInfoValues, selectedMarkets: [...surveyInfoValues.selectedMarkets, ...filterPreviouslyAddedMarkets] });
      handleValidation(surveyInfoValues.selectedMarkets, "selectedMarkets", [], errors, setErrors)
      setSelectedModalMarkets([])
      closeModal()
    } else {
      setSurveyInfoValues({ ...surveyInfoValues, selectedMarkets: selectedModalMarkets });
      handleValidation(surveyInfoValues.selectedMarkets, "selectedMarkets", [], errors, setErrors)
      setSelectedModalMarkets([])
      closeModal()
    }
  }

  return (
    <>
      <Modal show={showCountryModal} >
        <Modal.Header>
          <Modal.Title>Add market</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column jusify-content-center align-items-center ' >
          {isLoading ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
            :
            <>
              <div className="mb-0 w-100">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectCountry}
                  onChange={(e) => handleCountrySelect(e.target.value)}
                >
                  <option value={undefined}>Select country</option>
                  {countriesData && countriesData.map((country: string) =>
                    <option key={country} value={country} >
                      {country}
                    </option>
                  )}
                </select>
              </div><table className="table mb-0">
                <thead>
                  <tr>
                    <td colSpan={2} className="ps-3 fs-6 text-muted border-0"></td>
                    <td colSpan={1} className="p-0 border-0"></td>
                  </tr>
                </thead>
                <tbody>
                  {selectedModalMarkets && selectedModalMarkets.map((market, index) =>
                    <MarketModalRow key={`${market}-${index}`} marketName={market.MarketName} handleTableRowDelete={handleTableRowDelete} selectedModalMarkets={surveyInfoValues.selectedMarkets} setCountryIsSelected={setCountryIsSelected} />
                  )}
                </tbody>
              </table>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={countryIsSelected} onClick={handleSubmit}>
            Add market
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
