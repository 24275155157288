import * as React from "react";

import { BiddingContextValues } from "../../../contexts/BiddingContext";
import { NavigationContextValues } from "../../../contexts/NavigationContext";
import { initialFullServiceValues } from "../../../state/initialSurveyTypeStates/intitialSurveyTypeStates";
import { handleValidation } from "../../../utils/handleValidation";
import { ModalInterface } from "../../../interfaces/modalInterface";
import { ModalMessage as Modal } from "../../shared/Modal/Modal";

interface SurveyInformationProps {
}

export const SurveyInformation: React.FunctionComponent<SurveyInformationProps> = (props) => {
  const { setCurrentNavSubPage } = React.useContext(NavigationContextValues)
  const {
    surveyInfoValues, setSurveyInfoValues,
    setSurveyTypePrevState, setFullService,
    projectTypePrevState, setProjectTypePrevState,
    projectType, setProjectType,
    errors, setErrors, userEmail
  } = React.useContext(BiddingContextValues)
  const [modal, setModal] = React.useState<ModalInterface>({ visibility: false, title: "", message: "" })


  const findFormErrors = () => {
    const { surveyName, surveyType, containsPersonalData } = surveyInfoValues;

    let newErrors = {}
    if (surveyName === "") {
      newErrors = { ...newErrors, surveyName: true }
    }
    if (surveyType === "") {
      newErrors = { ...newErrors, surveyType: true }
    }
    if (projectType === "") {
      newErrors = { ...newErrors, projectType: true }
    }
    if (containsPersonalData === "") {
      newErrors = { ...newErrors, containsPersonalData: true }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors })
      return false
    }
    return true
  };

  const handleSurveyType = (surveyInputType: string) => {
    if (surveyInputType === "AdHoc") {
      setSurveyInfoValues({ ...surveyInfoValues, surveyType: "AdHoc" })
      handleValidation(surveyInfoValues.surveyType, "surveyType", null, errors, setErrors)
    } else {
      setSurveyInfoValues({ ...surveyInfoValues, surveyType: "Tracker" })
      handleValidation(surveyInfoValues.surveyType, "surveyType", null, errors, setErrors)
    }
  };

  const handleProjectType = (projectType: string) => {
    if (projectType === "FullService") {
      setProjectType("FullService")
      handleValidation(projectType, "projectType", null, errors, setErrors)
    } else {
      setProjectType("SampleOnly")
      handleValidation(projectType, "projectType", null, errors, setErrors)
    }
  }

  const handlePII = (collectInformations: string) => {
    setSurveyInfoValues({ ...surveyInfoValues, containsPersonalData: collectInformations })
    handleValidation(surveyInfoValues.containsPersonalData, "containsPersonalData", null, errors, setErrors)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid = findFormErrors();
    if (isValid) {
      if (projectTypePrevState === "FullService" && projectType === "SampleOnly") {
        setFullService(initialFullServiceValues)
        setSurveyTypePrevState(surveyInfoValues.surveyType)
        setProjectTypePrevState(projectType)
        return setModal({ visibility: true, title: "Warning", message: 'This will change your project type from "Full Service" to "Sample Only" and the details provided for "Full Service" will be reset.' })
      } else {
        if (modal || projectType === "FullService") {
          setSurveyTypePrevState(surveyInfoValues.surveyType)
          setProjectTypePrevState(projectType)
          setCurrentNavSubPage(surveyInfoValues.surveyType)
        } else {
          return setModal({ visibility: true, title: "Warning", message: 'This will change your project type from "Full Service" to "Sample Only" and the details provided for "Full Service" will be reset.' })
        }
      }
    }
  }

  return (
    <>
      {modal.visibility &&
        <Modal
          handleModal={setModal}
          visibility={modal.visibility}
          title={modal.title}
          message={modal.message}
        />
      }
      <div className="container-fluid">
        <div className="row ps-default">
          <div className="d-flex justify-content-end align-items-center pt-4">
            <p className="p-0 m-0 me-2">{userEmail}</p>
            <a className="text-decoration-none me-3 text-indigo" href="/" role="button">Not you?</a>
            <a className="btn btn-transparent border-primary text-primary rounded-pill small px-3 me-3" href="https://walr.com/contact/" role="button">Get in touch</a>
          </div>
          <div className="w-100 max-width-500 py-5 mt-2 pe-2 needs-validation" >
            <p className="display-7 text-primary fw-bold mb-4">Enter survey information</p>
            <form className="needs-validation" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="surveyName" className="form-label text-primary mb-1 small">Enter a name for this survey</label>
                <input
                  className={`form-control py-2 ${errors.surveyName ? "is-invalid" : null}`}
                  type="text"
                  maxLength={60}
                  id="surveyName"
                  placeholder="Survey name"
                  value={surveyInfoValues.surveyName}
                  onChange={(e) => setSurveyInfoValues({ ...surveyInfoValues, surveyName: e.target.value })}
                  onBlur={() => handleValidation(surveyInfoValues.surveyName, 'surveyName', null, errors, setErrors)}
                />
                {errors && errors.surveyName &&
                  <div className="invalid-feedback">
                    This field is required.
                  </div>
                }
              </div>
              <p className="form-label text-primary mb-1 small">Select if one-time or recurring survey</p>
              <div className="form-check d-flex justify-content-start px-0" >
                <div
                  className="border-radio text-primary rounded cursor-pointer py-2 px-3 me-2"
                  onClick={() => handleSurveyType("AdHoc")}>
                  <input
                    className={`form-check-input ms-0 me-2 cursor-pointer ${errors.surveyType ? "is-invalid" : null}`}
                    type="radio"
                    name="selectSurveyType"
                    id="oneTimeAdHocSurvey"
                    readOnly
                    checked={surveyInfoValues.surveyType === "AdHoc"}
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="oneTimeAdHocSurvey">One time (Ad hoc)</label>
                </div>
                <div
                  className="border-radio text-primary rounded cursor-pointer py-2 px-3"
                  onClick={() => handleSurveyType("Tracker")}>
                  <input
                    className={`form-check-input ms-0 me-2 cursor-pointer ${errors.surveyType ? "is-invalid" : null}`}
                    type="radio"
                    name="selectSurveyType"
                    id="tracker"
                    readOnly
                    checked={surveyInfoValues.surveyType === "Tracker"}
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="tracker">Recurring (Tracker)</label>
                </div>
              </div>
              {errors && errors.surveyType &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>
              }
              <p className="form-label text-primary mb-1 mt-4 small">Select type of project</p>
              <div className="form-check d-flex justify-content-start px-0" >
                <div
                  className="border-radio text-primary rounded cursor-pointer px-3 py-2 me-2"
                  onClick={() => handleProjectType("FullService")}>
                  <input
                    className={`form-check-input ms-0 me-2 cursor-pointer ${errors.projectType ? "is-invalid" : null}`}
                    type="radio"
                    name="selectProjectType"
                    id="FullService"
                    readOnly
                    checked={projectType === "FullService"}
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="fullServiceProject">Full Service</label>
                </div>
                <div
                  className="border-radio text-primary rounded cursor-pointer px-3 py-2 me-2"
                  onClick={() => handleProjectType("SampleOnly")}>
                  <input
                    className={`form-check-input ms-0 me-2 cursor-pointer ${errors.projectType ? "is-invalid" : null}`}
                    type="radio"
                    name="selectProjectType"
                    id="SampleOnly"
                    readOnly
                    checked={projectType === "SampleOnly"}
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="sampleOnlyProject">Sample only</label>
                </div>
              </div>
              {errors && errors.projectType &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>
              }
              <p className="form-label text-primary mb-1 mt-4 small">Does your survey collect personal identifiable information (PII)?</p>
              <div className="pb-4">
                <div className="form-check d-flex justify-content-start pt-2 px-0" >
                  <div className="text-primary cursor-pointer  me-2">
                    <input
                      className={`form-check-input ms-0 me-2 cursor-pointer ${errors.containsPersonalData ? "is-invalid" : null}`}
                      type="radio"
                      name="Yes"
                      id="Yes"
                      readOnly
                      checked={surveyInfoValues.containsPersonalData === "Yes" && true}
                      onChange={(e) => handlePII(e.target.name)}
                    />
                    <label className="form-check-label cursor-pointer" htmlFor="Yes">Yes</label>
                  </div>
                  <div className="text-primary cursor-pointer px-3  me-2">
                    <input
                      className={`form-check-input ms-0 me-2 cursor-pointer ${errors.containsPersonalData ? "is-invalid" : null} `}
                      type="radio"
                      name="No"
                      id="No"
                      readOnly
                      checked={surveyInfoValues.containsPersonalData === "No" && true}
                      onChange={(e) => handlePII(e.target.name)}
                    />
                    <label className="form-check-label cursor-pointer" htmlFor="No">No</label>
                  </div>
                </div>
                {errors && errors.containsPersonalData &&
                  <div className="text-danger">
                    <small>This field is required.</small>
                  </div>
                }
              </div>
              <div className="d-grid">
                <button type="submit" value="Submit" className="btn btn-primary py-2 w-100 d-flex justify-content-center align-items-center">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}