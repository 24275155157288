export const getCurrentDate = () => {
  const date = new Date()
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const d = day > 9 ? day : "0" + day
  const m = month > 9 ? month : "0" + month

  return `${year}-${m}-${d}`;
}
