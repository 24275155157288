import React from 'react';

import iconList from './iconList.json';
import { returnIconSize } from './returnIconSize/returnIconSize';
import { IconObject, IconProps } from '../../../interfaces/iconInterfaces/iconInterfaces';

const icons: IconObject = iconList

export const Icon: React.FC<IconProps> = ({ type, fill, size, className }) => {
  const iconSize = returnIconSize(icons[type], size)

  return (
    <svg fill={fill} width={iconSize.width} height={iconSize.height} className={className} viewBox={`0 0 ${iconSize.width ? iconSize.width : '24'} ${iconSize.height ? iconSize.height : '24'}`} xmlns="http://www.w3.org/2000/svg">
      <path d={icons[type]?.id} />
    </svg>
  )
}
