import './App.scss'
import * as React from 'react';
import "bootstrap/dist/js/bootstrap"
import { Spinner } from "react-bootstrap";

import { Navbar } from './components/shared/Navbar/Navbar';
import { NavigationContextProvider, NavigationContextValues } from './contexts/NavigationContext';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { Feasibility } from './components/Feasibility/Feasibility';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FeasibilityContextProvider } from './contexts/FeasibilityContext';
import { CombineContext } from './utils/combineContext';
import { ToastMessageProvider } from './contexts/ToastContext';
import { BiddingContextProvider } from './contexts/BiddingContext';

type AppProps = {
  msalInstance: PublicClientApplication;
}

const HeaderLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}
const CreateBidFlow = () => {
  const { currentActivePage } = React.useContext(NavigationContextValues)
  return currentActivePage
}

export const App = ({ msalInstance }: AppProps) => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route element={
      <CombineContext components={[NavigationContextProvider, BiddingContextProvider, ToastMessageProvider, FeasibilityContextProvider]}>
        <HeaderLayout />
      </CombineContext>}
      children={
        <>
          <Route path="/" element={<CreateBidFlow />} />
          <Route path='feasibility/:id' element={<Feasibility />} />
          <Route path='feasibility/' errorElement={<Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />} />
        </>
      } />
  ));

  return (
    <div className="App container-fluid min-vh-100 d-flex flex-row p-0">
      <div className='navbar-placeholder'></div>
      <MsalProvider instance={msalInstance}>
        <RouterProvider router={router} />
      </MsalProvider>
    </div >

  );
};