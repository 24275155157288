import React, { createContext, useState } from 'react';

import {
  FeasibilityContextInterface,
  feasibilityGetValuesInterface,
  feasibilityPostValuesInterface,
} from '../interfaces/feasibilityInterfaces'

const FeasibilityContext = createContext({} as FeasibilityContextInterface);

export const Provider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  const [feasibilityGetValues, setFeasibilityGetValues] = useState<feasibilityGetValuesInterface>({
    id: "",
    referenceNumber: "",
    email: "",
    surveyName: "",
    surveyType: 0,
    serviceType: 0,
    markets: [
      {
        marketName: "",
        targetNumberOfCompletes: 0,
        incidenceRate: 0,
        costPerInterview: 0,
        totalCost: "",
        feasibility: 0,
        interlockedQuotas: [],
        quotas: [],
        screening: []
      }
    ],
    discount: null,
    totalPrice: "",
    containsPersonalData: false,
    lengthOfInterview: 0,
    launchDate: "",
    targetEndDate: "",
    completeAsap: false,
    targetingDetails: "",
    trackerProjectDetails: {
      frequency: "",
      exclusions: [],
      exclusionDuration: ""
    },
    fullServiceProjectDetails: {
      surveyScripting: 0,
      numberOfQuestions: 0,
      dataProcessingRequired: true,
      translationsRequired: true,
      costs: {
        programming: 0,
        translations: 0,
        dataProcessing: 0
      }
    },
    requestedDate: "",
    submittedDate: "",
    commissionedDate: "",
    attachedFilename: "",
    eTag: "",
    status: 0,
    feasibilityComment: "",
    nationalRepresentativeSample: false,
    generalPopulationSample: false
  })

  const [feasibilityPostValues, setFeasibilityPostValues] = useState<feasibilityPostValuesInterface>({
    markets: [
      {
        marketName: "",
        targetNumberOfCompletes: 0,
        incidenceRate: 0,
        costPerInterview: 0,
        totalCost: "",
        feasibility: 0
      }
    ],
    fullServiceCosts: {
      programming: 0,
      translations: 0,
      dataProcessing: 0
    },
    discount: 0,
    totalPrice: 0,
    comment: "",
    eTag: ""

  })

  const feasibilityValues = {
    feasibilityGetValues,
    setFeasibilityGetValues,
    feasibilityPostValues,
    setFeasibilityPostValues
  }

  return (
    <FeasibilityContext.Provider value={feasibilityValues}>
      {props.children}
    </FeasibilityContext.Provider>
  );
};

export const FeasibilityContextProvider = Provider;
export const FeasibilityContextValues = FeasibilityContext;