import * as React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ModalProps {
  handleModal: Function;
  visibility: boolean;
  title: string;
  message: string;
}

export const ModalMessage: React.FunctionComponent<ModalProps> = ({ handleModal, visibility, message, title }) => {

  return (<>
    <Modal show={visibility}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        {title !== "Warning" ?
          <Button variant="light" onClick={() => handleModal(false)}>
            Close
          </Button> :
          <>
            <Button variant="light" onClick={() => handleModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleModal(true)}>
              Confirm
            </Button>
          </>
        }
      </Modal.Footer>
    </Modal>
  </>
  );
};