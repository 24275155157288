import React from "react";
import ReactDOM from "react-dom/client";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";

import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root")!);

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
    redirectUri: `${window.location.origin}/feasibility/`
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
}

const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
  </React.StrictMode >
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
